import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { 
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent,
  IconButton,
  Typography,
  Slider,
  Alert
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return canvas.toDataURL('image/jpeg');
};

const PhotoUpload = ({ onPhotoChange }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [error, setError] = useState(null);
  
  // Maximum file size: 20MB (in bytes)
  const MAX_FILE_SIZE = 20 * 1024 * 1024;

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setError(`File size exceeds 20MB limit (${(file.size / (1024 * 1024)).toFixed(2)}MB)`);
        return;
      }
      
      // Clear any previous errors
      setError(null);
      
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
        setDialogOpen(true);
      });
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async () => {
    try {
      const croppedImageUrl = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(croppedImageUrl);
      onPhotoChange(croppedImageUrl);
      setDialogOpen(false);
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          p: 2,
          border: '2px dashed #ccc',
          borderRadius: 2,
          cursor: 'pointer',
          '&:hover': {
            borderColor: 'primary.main',
          },
        }}
        onClick={() => document.getElementById('photo-upload').click()}
      >
        {croppedImage ? (
          <img 
            src={croppedImage} 
            alt="Profile" 
            style={{ 
              width: '200px', 
              height: '250px', // Rectangular aspect ratio
              objectFit: 'cover',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }} 
          />
        ) : (
          <Box
            sx={{
              width: '200px',
              height: '250px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f5f5f5'
            }}
          >
            <IconButton size="large">
              <AddAPhotoIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
        <Typography>
          {croppedImage ? 'Click to change photo' : 'Upload Profile Photo (Max 20MB)'}
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mt: 1, width: '100%' }}>
            {error}
          </Alert>
        )}
        
        <input
          type="file"
          id="photo-upload"
          accept="image/*"
          onChange={onFileChange}
          style={{ display: 'none' }}
        />
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <div style={{ position: 'relative', height: 400 }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={4/5} // Rectangular aspect ratio (200x250)
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <Box sx={{ width: '100%', mt: 2 }}>
            <Typography gutterBottom>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="zoom-slider"
              onChange={(e, value) => setZoom(value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PhotoUpload; 