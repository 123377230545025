import React from 'react';
import { Typography, Container } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Typography variant="h4" align="center" gutterBottom>Refund Policy</Typography>
        <Typography variant="body1">
        At Modern Shaadi, we are committed to providing high-quality Marriage Biodata to our valued customers. We understand that occasionally, issues may arise that require a refund. To ensure a transparent and fair refund process, we have established the following refund policy for our intangible digital products like Marriage Biodata:
        <ul>
          <li><strong>Refund Claim Submission Window:</strong> 
            <ul>
              <li>Refund claims must be submitted via email to <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a> within 24 hours of the original payment date.</li>
              <li>After the 24-hour period has elapsed, no refund requests will be entertained.</li>
            </ul>
          </li>
          <li><strong>Requirements for Refund:</strong> 
            <ul>
              <li>We will make every effort to address and rectify any issues or concerns you may have with our digital products. </li>
              <li>If you encounter a problem, please contact our support team immediately via email to <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a>, providing a detailed description of the issue along with any relevant screenshots of files.</li>
              <li>We will put in a lot of effort to find a solution that meets your needs.</li>
            </ul>
            </li>
          <li><strong>Refund Process:</strong> 
            <ul>
              <li>You will be eligible for a 100% refund of the purchase price if, in spite of our best efforts, we are unable to satisfy you with the resolution.</li>
              <li>Once eligible, the refund will be processed right away, and it may take up to 4-5 business days for the credit to appear on your bill.</li>
            </ul>
          </li>
          <li><strong>Refund Method:</strong> 
            <ul>
              <li>Refunds will be issued using the same payment method used for the original purchase.</li>
              <li>If a refund is not feasible through the original payment method, we will work with you to find an alternative refund method that is mutually acceptable.</li>
            </ul>
          </li>
        </ul>
        <br />
        Refunds will not be provided in cases where the issue arises due to user error, misuse, or violation of the terms and conditions associated with our digital products. Also, no refunds will be granted for digital products that have been downloaded, accessed, or used beyond the 24-hour refund claim window.
        <br />
        <br />
        <strong>Changes to this Policy:</strong>
        <br />
        This refund policy may be updated or changed at any moment by us. Any modifications will be applied to all upcoming transactions and will be announced on our website. Before purchasing any of our digital products, please make sure you have read and understood this refund policy. By completing a purchase, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this policy. Do not hesitate to ask our support staff for help if you need clarification or if you have any queries about our refund policy.
        <br />
        <br />
        Thank you for choosing Modern Shaadi for your Marriage Biodata. We appreciate your trust in our products and services.
        <br />
        <br />
        Modern Shaadi
        <br />
        01 March 2025
        <br />
        <br />
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default RefundPolicy; 