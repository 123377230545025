import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { blogs } from '../data/blogs';
import { ArrowBack } from '@mui/icons-material';
import Navbar from './Navbar';
import Footer from './Footer';

// Custom renderer for buttons
const CtaButton = ({ children, href }) => {
  return (
    <Button 
      variant="contained" 
      color="primary"
      onClick={() => window.open(href, '_blank')}
      sx={{ 
        my: 2, 
        px: 3, 
        py: 1.5, 
        borderRadius: 2, 
        fontSize: '1rem', 
        fontWeight: 'bold',
        backgroundColor: '#ff3635',
        border: '2px solid #ff3635',
        textTransform: 'capitalize',
      }}
    >
      {children}
    </Button>
  );
};

// Custom renderer for CTA section
const CtaSection = (props) => {  
  // Extract props from different possible locations
  const title = props.title || (props.node?.properties?.title);
  const subtitle = props.subtitle || (props.node?.properties?.subtitle);
  const buttonText = props.buttonText || (props.node?.properties?.buttontext);
  const buttonUrl = props.buttonUrl || (props.node?.properties?.buttonurl);
    
  return (
    <Box 
      sx={{ 
        padding: '20px', 
        backgroundColor: 'rgb(243 199 251)',
        borderRadius: 2,
        my: 4
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h3" component="h2" sx={{ pt: 4, pb: 4 }} gutterBottom>
          <strong>{title || "Create Your Marriage Biodata"}</strong>
        </Typography>
        <Typography variant="h4" component="h3" sx={{ pt: 1, pb: 4 }}>
          {subtitle || "Begin your journey with us right now!"}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 3 }}>
          <button
            onClick={() => window.open(buttonUrl || "https://modernshaadi.com/", '_blank')}
            style={{
              padding: '12px 32px',
              borderRadius: '8px',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              backgroundColor: '#ff3635',
              color: 'white !important',
              border: '2px solid #ff3635',
              cursor: 'pointer',
              margin: '10px 0',
              textAlign: 'center',
              display: 'inline-block',
              textDecoration: 'none',
              fontFamily: 'inherit'
            }}
          >
            {buttonText || "Create My Biodata"}
          </button>
        </Box>
      </Container>
    </Box>
  );
};

function BlogPost() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const blog = blogs.find(b => b.slug === slug);

  if (!blog) {
    return (
      <Container>
        <Typography>Blog post not found</Typography>
      </Container>
    );
  }

  // Custom components for ReactMarkdown
  const components = {
    // Convert <cta-button> tags to our custom CTA buttons
    'cta-button': CtaButton,
    // Convert <cta-section> tags to our custom CTA section
    'cta-section': CtaSection
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate('/blog')}
          sx={{ mb: 2 }}
        >
          Back to Blog List
        </Button>

        {blog.coverImage && (
          <Box
            component="img"
            src={blog.coverImage}
            alt={blog.title}
            sx={{
              width: '100%',
              height: 400,
              objectFit: 'cover',
              borderRadius: 1,
              mb: 3
            }}
          />
        )}

        <Typography variant="h3" component="h1" gutterBottom>
          {blog.title}
        </Typography>
        
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
          Published on {new Date(blog.date).toLocaleDateString()}
        </Typography>

        <Box sx={{
          '& img': {
            maxWidth: '100%',
            height: 'auto',
          },
          '& h1, & h2, & h3, & h4, & h5, & h6': {
            mt: 4,
            mb: 2
          },
          '& p': {
            mb: 2,
            lineHeight: 1.7
          },
          '& .cta-button-container': {
            display: 'flex',
            justifyContent: 'center',
            my: 4
          }
        }}>
          <ReactMarkdown 
            components={components}
            rehypePlugins={[rehypeRaw]}
          >
            {blog.content}
          </ReactMarkdown>
          
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default BlogPost; 