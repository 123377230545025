import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Paper,
  Box,
  IconButton,
  Card,
  CardContent,
  Divider,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  InputLabel,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpIcon,
  ArrowDownward as ArrowDownIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Palette as PaletteIcon,
  FormatPaint as FormatPaintIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import TemplateSelection from './components/TemplateSelectionDesktop';
import TemplateSelectionMobile from './components/TemplateSelectionMobile';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import TemplateSlider from './components/TemplateSlider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from './components/Footer';
import './App.css';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import ContactUs from './pages/ContactUs';
import Navbar from './components/Navbar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PhotoUpload from './components/PhotoUpload';
import TemplateEditor from './components/TemplateEditor';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from './pages/Login';
import TemplateDownload from './components/TemplateDownload';
import ShippingAndDeliveryPolicy from './pages/ShippingAndDeliveryPolicy';
import AnalyticsRoute from './components/AnalyticsRoute';
import * as Analytics from './services/analyticsService';
import * as TemplateHelper from './components/TemplateSelectionHelper';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff',
    },
    secondary: {
      main: '#28a745',
    },
  },
});

// Define field sets for each category
const categoryFields = {
  Progressive: {
    personal: [
      { id: 'fullName', label: 'Name', type: 'text', required: true },
      { id: 'dob', label: 'Date of Birth', type: 'date', required: true },
      { id: 'placeOfBirth', label: 'Place of Birth', type: 'text', required: true },
      { id: 'gender', label: 'Gender', type: 'select', 
        options: ['Male', 'Female', 'Non-binary', 'Other'] },
      { id: 'education', label: 'Education', type: 'text' },
      { id: 'occupation', label: 'Occupation', type: 'text' },
      { id: 'height', label: 'Height', type: 'text' },
      { id: 'languages', label: 'Languages Known', type: 'text' },
      { id: 'interests', label: 'Interests & Hobbies', type: 'text' },
      { id: 'diet', label: 'Dietary Preference', type: 'select',
        options: ['Vegetarian', 'Non-vegetarian', 'Vegan', 'No preference'] },
      { id: "aboutMe", label: "About Me", type: "text" }
    ],
    family: [
      { id: 'fatherName', label: "Father's Name", type: 'text' },
      { id: 'fatherOccupation', label: "Father's Occupation", type: 'text' },
      { id: 'motherName', label: "Mother's Name", type: 'text' },
      { id: 'motherOccupation', label: "Mother's Occupation", type: 'text' },
      { id: 'siblings', label: 'Siblings', type: 'text' }
    ],
    contact: [
      { id: 'contactPerson', label: 'Contact Person', type: 'text' },
      { id: 'phone', label: 'Phone Number', type: 'tel' },
      { id: 'email', label: 'Email', type: 'email' },
      { id: 'address', label: 'Current Address', type: 'text' }
    ]
  },
  Hindu: {
    personal: [
      { id: 'fullName', label: 'Name', type: 'text', required: true },
      { id: 'dob', label: 'Date of Birth', type: 'date', required: true },
      { id: 'placeOfBirth', label: 'Place of Birth', type: 'text', required: true },
      { id: 'timeOfBirth', label: 'Time of Birth', type: 'text' },
      { id: 'rashi', label: 'Rashi', type: 'select', 
        options: ['Mesh (Aries)', 'Vrishabha (Taurus)', 'Mithun (Gemini)', 'Karka (Cancer)', 'Simha (Leo)', 'Kanya (Virgo)', 'Tula (Libra)', 'Vrishchika (Scorpio)', 'Dhanu (Sagittarius)', 'Makara (Capricorn)', 'Kumbha (Aquarius)', 'Meena (Pisces)'] },
      { id: 'nakshatra', label: 'Nakshatra', type: 'text' },
      { id: 'complexion', label: 'Complexion', type: 'select', 
        options: ['Very Fair', 'Fair', 'Medium', 'Brown', 'Dark'] },
      { id: 'height', label: 'Height', type: 'text' },
      { id: 'gotra', label: 'Gotra', type: 'text' },
      { id: 'gender', label: 'Gender', type: 'select', 
        options: ['Male', 'Female', 'Other'] },
      { id: 'education', label: 'Education', type: 'text' },
      { id: 'occupation', label: 'Occupation', type: 'text' }
    ],
    family: [
      { id: 'fatherName', label: "Father's Name", type: 'text' },
      { id: 'fatherOccupation', label: "Father's Occupation", type: 'text' },
      { id: 'motherName', label: "Mother's Name", type: 'text' },
      { id: 'motherOccupation', label: "Mother's Occupation", type: 'text' },
      { id: 'siblings', label: 'Siblings', type: 'text' }
    ],
    contact: [
      { id: 'contactPerson', label: 'Contact Person', type: 'text' },
      { id: 'phone', label: 'Phone Number', type: 'tel' },
      { id: 'email', label: 'Email', type: 'email' },
      { id: 'address', label: 'Current Address', type: 'text' }
    ]
  },
  Muslim: {
    personal: [
      { id: 'fullName', label: 'Name', type: 'text', required: true },
      { id: 'dob', label: 'Date of Birth', type: 'date', required: true },
      { id: 'placeOfBirth', label: 'Place of Birth', type: 'text', required: true },
      { id: 'sect', label: 'Sect', type: 'select', 
        options: ['Sunni', 'Shia', 'Other'] },
      { id: 'religiousStatus', label: 'Religious Status', type: 'select',
        options: ['Very Religious', 'Moderate', 'Not Very Religious'] },
      { id: 'complexion', label: 'Complexion', type: 'select', 
        options: ['Very Fair', 'Fair', 'Medium', 'Brown', 'Dark'] },
      { id: 'height', label: 'Height', type: 'text' },
      { id: 'gender', label: 'Gender', type: 'select', 
        options: ['Male', 'Female', 'Other'] },
      { id: 'education', label: 'Education', type: 'text' },
      { id: 'occupation', label: 'Occupation', type: 'text' }
    ],
    family: [
      { id: 'fatherName', label: "Father's Name", type: 'text' },
      { id: 'fatherOccupation', label: "Father's Occupation", type: 'text' },
      { id: 'motherName', label: "Mother's Name", type: 'text' },
      { id: 'motherOccupation', label: "Mother's Occupation", type: 'text' },
      { id: 'siblings', label: 'Siblings', type: 'text' }
    ],
    contact: [
      { id: 'contactPerson', label: 'Contact Person (Wali)', type: 'text' },
      { id: 'phone', label: 'Phone Number', type: 'tel' },
      { id: 'email', label: 'Email', type: 'email' },
      { id: 'address', label: 'Current Address', type: 'text' }
    ]
  }
};

function FormFields({ fields, onMoveField, onDeleteField, onFieldChange, values }) {
  return (
    <Grid container spacing={2}>
      {fields.map((field, index) => (
        <Grid item xs={12} key={field.id}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
            backgroundColor: 'background.paper',
            p: 1,
            borderRadius: 1,
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography
                sx={{
                  
                  fontWeight: 'medium',
                  color: 'text.secondary'
                }}
              >
                {field.label}
                {field.required && ' *'}
              </Typography>

              <Box sx={{ display: 'flex', gap: 1, marginLeft: 'auto' }}>
                <IconButton 
                  size="small"
                  onClick={() => onMoveField(index, -1)}
                  disabled={index === 0}
                  sx={{ p: '4px' }}
                >
                  <ArrowUpIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small"
                  onClick={() => onMoveField(index, 1)}
                  disabled={index === fields.length - 1}
                  sx={{ p: '4px' }}
                >
                  <ArrowDownIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small"
                  onClick={() => onDeleteField(index)}
                  sx={{ p: '4px', color: 'error.main' }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1, width: '100%' }}>
              {field.type === 'time' ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={values[field.id] ? dayjs(values[field.id]) : null}
                    onChange={(newValue) => onFieldChange(field.id, newValue ? newValue.format() : null)}
                    slotProps={{ textField: { fullWidth: true, size: "small" } }}
                  />
                </LocalizationProvider>
              ) : field.type === 'select' ? (
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  size="small"
                  required={field.required}
                  value={values[field.id] || ''}
                  onChange={(e) => onFieldChange(field.id, e.target.value)}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  fullWidth
                  type={field.type}
                  required={field.required}
                  variant="outlined"
                  size="small"
                  value={values[field.id] || ''}
                  onChange={(e) => onFieldChange(field.id, e.target.value)}
                  InputLabelProps={
                    field.type === 'date' ? { shrink: true } : undefined
                  }
                />
              )}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

function AddFieldDialog({ open, onClose, onAdd }) {
  const [fieldData, setFieldData] = useState({
    label: '',
    type: 'text',
    required: false,
  });

  const handleSubmit = () => {
    if (fieldData.label.trim()) {
      onAdd({
        ...fieldData,
        id: `custom-${Date.now()}`,
      });
      onClose();
      setFieldData({ label: '', type: 'text', required: false });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add More Details</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Field Label"
            value={fieldData.label}
            onChange={(e) => setFieldData({ ...fieldData, label: e.target.value })}
            fullWidth
          />
          <FormControl fullWidth>
            <Select
              value={fieldData.type}
              label="Field Type"
              onChange={(e) => setFieldData({ ...fieldData, type: e.target.value })}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="tel">Phone</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">Add Field</Button>
      </DialogActions>
    </Dialog>
  );
}

// Add this function before the App component
function checkAndClearOldCookies(maxAgeInMinutes = 1440) { // Default is 1 day (1440 minutes)
  try {
    // Get the cookie that stores the timestamp of when cookies were last set
    const cookieTimestamp = getCookie('cookieTimestamp');
    
    if (cookieTimestamp) {
      const currentTime = new Date().getTime();
      const cookieTime = parseInt(cookieTimestamp, 10);
      
      // Calculate age in minutes
      const ageInMinutes = (currentTime - cookieTime) / (1000 * 60);
      
      // If cookies are older than the specified max age, clear them
      if (ageInMinutes > maxAgeInMinutes) {
        clearAllWebsiteCookies();
        // Set a new timestamp
        setCookie('cookieTimestamp', currentTime.toString(), 365);
      }
    } else {
      // If no timestamp exists, set one now
      setCookie('cookieTimestamp', new Date().getTime().toString(), 365);
    }
  } catch (error) {
    console.error('Error checking cookie age:', error);
  }
}

// Helper function to get a specific cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

// Helper function to set a cookie
function setCookie(name, value, daysToExpire) {
  const date = new Date();
  date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

// Helper function to clear all cookies from your domain
function clearAllWebsiteCookies() {
  // Clear cookies
  const cookies = document.cookie.split(';');
  
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
    
    // Skip third-party cookies by checking the domain
    // This is a simple check - cookies without domain specification are from your domain
    if (!name.includes('_ga') && !name.includes('_gid') && !name.includes('_fbp')) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
  
  // Also clear localStorage
  localStorage.clear();
  
  console.log('Website cookies and localStorage have been cleared due to age');
}

function App() {
  const [selectedCategory, setSelectedCategory] = useState(() => {
    return localStorage.getItem('selectedCategory') || 'Hindu';
  });

  const [personalFieldsList, setPersonalFieldsList] = useState(() => {
    const cachedFields = localStorage.getItem(`${selectedCategory}_personalFieldsList`);
    return cachedFields ? JSON.parse(cachedFields) : categoryFields[selectedCategory].personal;
  });

  const [familyFieldsList, setFamilyFieldsList] = useState(() => {
    const cachedFields = localStorage.getItem(`${selectedCategory}_familyFieldsList`);
    return cachedFields ? JSON.parse(cachedFields) : categoryFields[selectedCategory].family;
  });

  const [contactFieldsList, setContactFieldsList] = useState(() => {
    const cachedFields = localStorage.getItem(`${selectedCategory}_contactFieldsList`);
    return cachedFields ? JSON.parse(cachedFields) : categoryFields[selectedCategory].contact;
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const navigate = useNavigate();
  const [personalValues, setPersonalValues] = useState(() => {
    const cached = localStorage.getItem(`${selectedCategory}_personalValues`);
    return cached ? JSON.parse(cached) : {};
  });
  const [familyValues, setFamilyValues] = useState(() => {
    const cached = localStorage.getItem(`${selectedCategory}_familyValues`);
    return cached ? JSON.parse(cached) : {};
  });
  const [contactValues, setContactValues] = useState(() => {
    const cached = localStorage.getItem(`${selectedCategory}_contactValues`);
    return cached ? JSON.parse(cached) : {};
  });
  const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(() => {
    const cached = localStorage.getItem('profilePhoto');
    return cached || null;
  });
  const formRef = useRef(null);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [showSaleBanner, setShowSaleBanner] = useState(true);
  const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds

  // Add state for preloaded templates
  const [preloadedTemplates, setPreloadedTemplates] = useState([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(true);

  // Track page view when component mounts
  useEffect(() => {
    Analytics.trackPageView('/');
    Analytics.trackSessionStart(document.referrer);
    
    // Check for old cookies
    checkAndClearOldCookies();
  }, []);
  
  // Track category selection
  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    Analytics.trackEvent('Form Interaction', 'category_change', newCategory);
    setSelectedCategory(newCategory);
  };

  // Update resize handler to track screen size
  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      
      // Track screen size change only when it crosses the mobile threshold
      if (newIsMobile !== isMobile) {
        Analytics.trackEvent('User Info', 'screen_size_change', newIsMobile ? 'mobile' : 'desktop');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on mount

    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  useEffect(() => {
    localStorage.setItem('selectedCategory', selectedCategory);
    
    setPersonalFieldsList(
      localStorage.getItem(`${selectedCategory}_personalFieldsList`) 
        ? JSON.parse(localStorage.getItem(`${selectedCategory}_personalFieldsList`))
        : categoryFields[selectedCategory].personal
    );
    
    setFamilyFieldsList(
      localStorage.getItem(`${selectedCategory}_familyFieldsList`)
        ? JSON.parse(localStorage.getItem(`${selectedCategory}_familyFieldsList`))
        : categoryFields[selectedCategory].family
    );
    
    setContactFieldsList(
      localStorage.getItem(`${selectedCategory}_contactFieldsList`)
        ? JSON.parse(localStorage.getItem(`${selectedCategory}_contactFieldsList`))
        : categoryFields[selectedCategory].contact
    );
    
    setPersonalValues(
      localStorage.getItem(`${selectedCategory}_personalValues`)
        ? JSON.parse(localStorage.getItem(`${selectedCategory}_personalValues`))
        : {}
    );
    
    setFamilyValues(
      localStorage.getItem(`${selectedCategory}_familyValues`)
        ? JSON.parse(localStorage.getItem(`${selectedCategory}_familyValues`))
        : {}
    );
    
    setContactValues(
      localStorage.getItem(`${selectedCategory}_contactValues`)
        ? JSON.parse(localStorage.getItem(`${selectedCategory}_contactValues`))
        : {}
    );
  }, [selectedCategory]);

  useEffect(() => {
    localStorage.setItem(`${selectedCategory}_personalValues`, JSON.stringify(personalValues));
    localStorage.setItem(`${selectedCategory}_familyValues`, JSON.stringify(familyValues));
    localStorage.setItem(`${selectedCategory}_contactValues`, JSON.stringify(contactValues));
    localStorage.setItem(`${selectedCategory}_personalFieldsList`, JSON.stringify(personalFieldsList));
    localStorage.setItem(`${selectedCategory}_familyFieldsList`, JSON.stringify(familyFieldsList));
    localStorage.setItem(`${selectedCategory}_contactFieldsList`, JSON.stringify(contactFieldsList));
    if (profilePhoto) {
      localStorage.setItem('profilePhoto', profilePhoto);
    } else {
      localStorage.removeItem('profilePhoto');
    }
  }, [personalValues, familyValues, contactValues, personalFieldsList, familyFieldsList, contactFieldsList, profilePhoto, selectedCategory]);

  // Add this useEffect to check cookies on component mount
  useEffect(() => {
    checkAndClearOldCookies(360); // Clear cookies every 60 minutes
  }, []);

  // Check if user has seen the banner before
  useEffect(() => {
    const hasSeenBanner = localStorage.getItem('hasSeenLimitedTimeOfferBanner');
    if (hasSeenBanner) {
      setShowSaleBanner(false);
    }

    // Timer logic
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setShowSaleBanner(false);
          localStorage.setItem('hasSeenLimitedTimeOfferBanner', 'true');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleCloseBanner = () => {
    Analytics.trackEvent('UI Interaction', 'banner_close', 'sale_banner');
    setShowSaleBanner(false);
  };

  const handleAddField = (section) => {
    Analytics.trackEvent('Form Customization', 'add_field_dialog', section);
    setActiveSection(section);
    setDialogOpen(true);
  };

  const handleNewField = (newField) => {
    Analytics.trackEvent('Form Customization', 'create_field', `${activeSection}:${newField.label}`);
    
    switch (activeSection) {
      case 'personal':
        setPersonalFieldsList([...personalFieldsList, newField]);
        break;
      case 'family':
        setFamilyFieldsList([...familyFieldsList, newField]);
        break;
      case 'contact':
        setContactFieldsList([...contactFieldsList, newField]);
        break;
    }
  };

  const handleMoveField = (section, index, direction) => {
    Analytics.trackEvent('Form Customization', 'move_field', `${section}:${direction}`);
    
    const setFields = {
      personal: setPersonalFieldsList,
      family: setFamilyFieldsList,
      contact: setContactFieldsList,
    }[section];

    const fields = {
      personal: personalFieldsList,
      family: familyFieldsList,
      contact: contactFieldsList,
    }[section];

    const newFields = [...fields];
    const temp = newFields[index];
    newFields[index] = newFields[index + direction];
    newFields[index + direction] = temp;
    setFields(newFields);
  };

  const handleDeleteField = (section, index) => {
    const fields = {
      personal: personalFieldsList,
      family: familyFieldsList,
      contact: contactFieldsList,
    }[section];
    
    // Track which field was deleted
    Analytics.trackEvent('Form Customization', 'delete_field', `${section}:${fields[index].label}`);

    const setFields = {
      personal: setPersonalFieldsList,
      family: setFamilyFieldsList,
      contact: setContactFieldsList,
    }[section];

    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleFieldChange = (section, fieldId, value) => {
    let shouldTrack = true;
    
    // Don't track every keystroke for text fields - only track when field loses focus
    if (typeof value === 'string' && value.length > 0) {
      shouldTrack = false;
    }
    
    if (shouldTrack) {
      Analytics.trackFormField(fieldId, 'completed', `${selectedCategory}-${section}`);
    }
    
    switch (section) {
      case 'personal':
        setPersonalValues(prev => ({ ...prev, [fieldId]: value }));
        break;
      case 'family':
        setFamilyValues(prev => ({ ...prev, [fieldId]: value }));
        break;
      case 'contact':
        setContactValues(prev => ({ ...prev, [fieldId]: value }));
        break;
    }
  };

  const handleOpenTemplateModal = () => {
    // Calculate form completion percentage
    const calculateFormCompletion = () => {
      let filledFields = 0;
      let totalFields = 0;
      
      // Count personal fields
      personalFieldsList.forEach(field => {
        totalFields++;
        if (personalValues[field.id] && personalValues[field.id] !== '') {
          filledFields++;
        }
      });
      
      // Count family fields
      familyFieldsList.forEach(field => {
        totalFields++;
        if (familyValues[field.id] && familyValues[field.id] !== '') {
          filledFields++;
        }
      });
      
      // Count contact fields
      contactFieldsList.forEach(field => {
        totalFields++;
        if (contactValues[field.id] && contactValues[field.id] !== '') {
          filledFields++;
        }
      });
      
      // Add profile photo to count
      totalFields++;
      if (profilePhoto) {
        filledFields++;
      }
      
      return (filledFields / totalFields) * 100;
    };
    
    const completionPercentage = calculateFormCompletion();
    
    // Track form submission and completion rate
    Analytics.trackFormSubmission('biodata-form', true);
    Analytics.trackFormProgress('form_completed');
    Analytics.trackFormPartialCompletion(completionPercentage);
    
    // Track button click
    Analytics.trackButtonClick('Choose Template', 'choose_template_button');
    
    // Prepare form data to pass to template selection with proper labels
    // Create organized data with labels
    const organizedPersonalData = {};
    personalFieldsList.forEach(field => {
      if (personalValues[field.id]) {
        organizedPersonalData[field.label] = personalValues[field.id];
      }
    });

    const organizedFamilyData = {};
    familyFieldsList.forEach(field => {
      if (familyValues[field.id]) {
        organizedFamilyData[field.label] = familyValues[field.id];
      }
    });

    const organizedContactData = {};
    contactFieldsList.forEach(field => {
      if (contactValues[field.id]) {
        organizedContactData[field.label] = contactValues[field.id];
      }
    });

    const formData = {
      category: selectedCategory,
      "Personal Details": organizedPersonalData,
      "Family Details": organizedFamilyData,
      "Contact Details": organizedContactData,
      profilePhoto: profilePhoto,
      // Also include raw values for any component that might need them
      rawData: {
        personalValues,
        familyValues,
        contactValues
      }
    };
    
    setFormData(formData);
    setTemplateModalOpen(true);
  };

  const handleCloseTemplateModal = () => {
    Analytics.trackModalClose('template_selection', 'template_selection_modal_close');
    setTemplateModalOpen(false);
  };

  const handleResetForm = () => {
    Analytics.trackButtonClick('Reset Form', 'reset_form_button');
    
    const confirmReset = window.confirm('Are you sure you want to reset the form? All your data will be lost.');
    
    if (confirmReset) {
      Analytics.trackEvent('Form Interaction', 'form_reset_confirmed', selectedCategory);
      
      setPersonalValues({});
      setFamilyValues({});
      setContactValues({});
      setProfilePhoto(null);
      
      // Clear localStorage for this category
      localStorage.removeItem(`${selectedCategory}_personalValues`);
      localStorage.removeItem(`${selectedCategory}_familyValues`);
      localStorage.removeItem(`${selectedCategory}_contactValues`);
      localStorage.removeItem('profilePhoto');
      
      // Reset fields to default for this category
      setPersonalFieldsList(categoryFields[selectedCategory].personal);
      setFamilyFieldsList(categoryFields[selectedCategory].family);
      setContactFieldsList(categoryFields[selectedCategory].contact);
      
      localStorage.removeItem(`${selectedCategory}_personalFieldsList`);
      localStorage.removeItem(`${selectedCategory}_familyFieldsList`);
      localStorage.removeItem(`${selectedCategory}_contactFieldsList`);
    } else {
      Analytics.trackEvent('Form Interaction', 'form_reset_cancelled', selectedCategory);
    }
  };

  // Add a function to preload templates
  const preloadTemplates = async () => {
    try {
      setIsTemplatesLoading(true);
      const fetchTemplates = TemplateHelper.fetchTemplatesFromFirestore();
      const templatesData = await fetchTemplates();
      
      // Sort templates - free templates first
      const sortedTemplates = [...templatesData].sort((a, b) => {
        const aIsFree = a.pricing?.regions?.IN?.isFree || false;
        const bIsFree = b.pricing?.regions?.IN?.isFree || false;
        if (aIsFree && !bIsFree) return -1;
        if (!aIsFree && bIsFree) return 1;
        return 0;
      });
      
      setPreloadedTemplates(sortedTemplates);
    } catch (error) {
      console.error('Error preloading templates:', error);
    } finally {
      setIsTemplatesLoading(false);
    }
  };

  // Preload templates when component mounts
  useEffect(() => {
    preloadTemplates();
  }, []);

  return (
    <div className="App">
      {showSaleBanner && (
        <div className="sale-banner">
          <div className="sale-banner-content">
            <Typography variant="h6" component="span">Limited Time Offer: 50% off on all designs</Typography>
            <Typography variant="body1" component="span">
              Time left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
            </Typography>
          </div>
          <IconButton 
            onClick={handleCloseBanner} 
            size="small" 
            className="sale-banner-close"
            aria-label="close banner"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      
      <Navbar />

      <div className="hero-section">
        <div className="hero-content" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          {isMobile ? (
            <>
              <div className="hero-images">
                <img src="/imgs/sampleBio/eg3.png" alt="Biodata example 1" className="sample-image" />
                <img src="/imgs/sampleBio/eg8.png" alt="Biodata example 2" className="sample-image" />
                <img src="/imgs/sampleBio/eg4.png" alt="Biodata example 3" className="sample-image" />
              </div>
              <div className="hero-text">
                <h1>Your Premium Marriage Biodata Creator!</h1>
                <p>Your journey to finding the ideal match begins with a perfect biodata. At Modern Shaadi, we specialize in crafting elegant, organized, and customized marriage biodatas that truly showcase your individuality and family values.
                </p>
                <button 
                  className="cta-button" 
                  onClick={() => formRef.current.scrollIntoView({ behavior: 'smooth' })}
                >
                  Create My Biodata
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="hero-text">
                <h1>Your Premium Marriage Biodata Creator!</h1>
                <p>Your journey to finding the ideal match begins with a perfect biodata. At Modern Shaadi, we specialize in crafting elegant, organized, and customized marriage biodatas that truly showcase your individuality and family values.
                </p>
                <button 
                  className="cta-button" 
                  onClick={() => formRef.current.scrollIntoView({ behavior: 'smooth' })}
                >
                  Create My Biodata
                </button>
              </div>
              <div className="hero-images">
                <img src="/imgs/sampleBio/eg3.png" alt="Biodata example 1" className="sample-image" />
                <img src="/imgs/sampleBio/eg8.png" alt="Biodata example 2" className="sample-image" />
                <img src="/imgs/sampleBio/eg4.png" alt="Biodata example 3" className="sample-image" />
              </div>
            </>
          )}
        </div>
        <div className="diagonal-divider"></div>
      </div>

      <div className="template-showcase">
        <div className="container mx-auto px-4 py-16">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">
              Beautifully Designed Templates Which Complement Your Personality
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              We have created stunning, professionally formatted marriage biodata templates just for you. Simply pick your favourite design and effortlessly create your biodata without any stress. Say goodbye to spending hours on layouts and designs – we've already perfected it for you, ensuring your marriage biodata looks polished and impressive.
            </p>
          </div>
          <TemplateSlider />
        </div>
      </div>

      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <Container maxWidth="lg">
            <Box sx={{ my: 4, textAlign: 'center' }}>
              <Typography variant="h3" component="h1" gutterBottom>
                Create Beautiful Marriage Biodatas
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ mb: 6, maxWidth: 800, mx: 'auto' }}>
                Create intimate and customizable biodatas in minutes.
                Easy to edit, multiple templates to choose from, and instant PDF generation.
              </Typography>
            </Box>

            <Box sx={{ my: 4 }}>
              <Typography variant="h4" component="h2" gutterBottom>
                How it works
              </Typography>
              <Grid container spacing={4} justifyContent="center">
                {[1, 2, 3].map((number) => {
                  const circleColor = number === 1 ? '#eb8b8b' : number === 2 ? '#e3e388' : '#8ce2ad';
                  return (
                    <Grid item xs={12} sm={4} key={number}>
                      <Card sx={{ textAlign: 'center', p: 3 }}>
                        <Box className="circle-number" sx={{ backgroundColor: circleColor, borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}>
                          {number}
                        </Box>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          {number === 1 ? 'Fill in Your Details' : number === 2 ? 'Choose a Template' : 'Preview & Download'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {number === 1 ? 'Enter your personal, family, and professional details in a guided form. You can also add profile picture' 
                            : number === 2 ? 'Choose from a variety of elegant and professional designs. Just choose the template and wait for the magic' 
                            : 'Everything is done now, just preview and generate your biodata and share it with ease. Take first step towards your dream match.'}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Paper sx={{ p: 3, mb: 4 }} ref={formRef}>
              <Box component="form">
                <Card sx={{ mb: 3 }}>
                  <CardContent sx={{ '&:last-child': { pb: 3 } }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Select Biodata Type
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                      Choose the type of biodata that best represents your preferences and cultural background.
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                      {['Hindu', 'Muslim', 'Progressive'].map((category) => (
                        <Grid item xs={12} sm={4} key={category}>
                          <Button
                            variant={selectedCategory === category ? "contained" : "outlined"}
                            sx={{ 
                              backgroundColor: selectedCategory === category ? '#ff3635' : 'transparent',
                              color: selectedCategory === category ? 'black' : '#ff3635',
                              borderRadius: 15,
                              py: 2,
                              fontWeight: selectedCategory === category ? 'bold' : 'normal',
                              border: selectedCategory === category ? 'none' : `2px solid #ff3635`
                            }}
                            fullWidth
                            onClick={() => setSelectedCategory(category)}
                          >
                            {category}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>

                <Card sx={{ mb: 3 }}>
                  <CardContent sx={{ '&:last-child': { pb: 3 } }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Profile Photo
                    </Typography>
                    <PhotoUpload onPhotoChange={setProfilePhoto} />
                  </CardContent>
                </Card>

                <Card sx={{ mb: 3 }}>
                  <CardContent sx={{ '&:last-child': { pb: 3 } }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Personal Details
                    </Typography>
                    <Box sx={{ my: 2 }}>
                      <FormFields 
                        fields={personalFieldsList}
                        onMoveField={(index, direction) => handleMoveField('personal', index, direction)}
                        onDeleteField={(index) => handleDeleteField('personal', index)}
                        onFieldChange={(fieldId, value) => handleFieldChange('personal', fieldId, value)}
                        values={personalValues}
                      />
                    </Box>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{ mt: 2 }}
                      onClick={() => handleAddField('personal')}
                    >
                      Add More Details
                    </Button>
                  </CardContent>
                </Card>

                <Card sx={{ mb: 3 }}>
                  <CardContent sx={{ '&:last-child': { pb: 3 } }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Family Details
                    </Typography>
                    <Box sx={{ my: 2 }}>
                      <FormFields 
                        fields={familyFieldsList}
                        onMoveField={(index, direction) => handleMoveField('family', index, direction)}
                        onDeleteField={(index) => handleDeleteField('family', index)}
                        onFieldChange={(fieldId, value) => handleFieldChange('family', fieldId, value)}
                        values={familyValues}
                      />
                    </Box>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{ mt: 2 }}
                      onClick={() => handleAddField('family')}
                    >
                      Add More Details
                    </Button>
                  </CardContent>
                </Card>

                <Card sx={{ mb: 3 }}>
                  <CardContent sx={{ '&:last-child': { pb: 3 } }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Contact Details
                    </Typography>
                    <Box sx={{ my: 2 }}>
                      <FormFields 
                        fields={contactFieldsList}
                        onMoveField={(index, direction) => handleMoveField('contact', index, direction)}
                        onDeleteField={(index) => handleDeleteField('contact', index)}
                        onFieldChange={(fieldId, value) => handleFieldChange('contact', fieldId, value)}
                        values={contactValues}
                      />
                    </Box>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{ mt: 2 }}
                      onClick={() => handleAddField('contact')}
                    >
                      Add More Field
                    </Button>
                  </CardContent>
                </Card>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={handleResetForm}
                  >
                    Reset Form
                  </Button>
                  <Box sx={{ position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleOpenTemplateModal}
                    >
                      Choose Template
                    </Button>
                  </Box>
                </Box>
              </Box>

              <AddFieldDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAdd={handleNewField}
              />
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
      <div className="essential-features" style={{ backgroundColor: '#8B4513', padding: '20px', color: 'white' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" gutterBottom>
            Why Choose Modern Shaadi?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <CardContent>
                  <IconButton sx={{ color: '#4CAF50' }}>
                    <CheckCircleIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="h5" gutterBottom>Easy to use</Typography>
                  <Typography variant="body2">Create your marriage biodata in just a few minutes with our simple and user-friendly interface.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <CardContent>
                  <IconButton sx={{ color: '#FF9800' }}>
                    <PaletteIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="h5" gutterBottom>Customization Options</Typography>
                  <Typography variant="body2">Personalize your biodata with colours, fonts, and layout choices that make it unique.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <CardContent>
                  <IconButton sx={{ color: '#2196F3' }}>
                    <FormatPaintIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="h5" gutterBottom>Modern Stylish Templates</Typography>
                  <Typography variant="body2">Choose from a variety of modern and traditional templates to suit your preferences.</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="essential-details">
        <Container maxWidth="lg" sx={{ my: 4 }}>
          <Typography variant="h2" component="h2" gutterBottom>
            What to Include in Your Marriage Biodata?
          </Typography>
          <Typography variant="body1" color="text.secondary">A marriage biodata is a detailed document that highlights your personal, educational, professional, and family background, along with your cultural values. Since this is often the first introduction to your potential partner, it's crucial to make it both engaging and informative to leave a memorable impression. Here are some essential details we recommend including in your marriage biodata to ensure it stands out and effectively represents you.
          </Typography>
          <Grid container spacing={4} sx={{ pt: 8 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" color="text.secondary" align="left">
                <strong>1. Name:</strong> <br />
                Always include your full name to establish a clear identity.<br /><br />
                
                <strong>2. Date of Birth:</strong> <br />Your date of birth is a crucial detail in a marriage biodata, as it helps determine age compatibility. It's also essential for cultural practices like horoscope matching.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Date of Birth:</strong> 4th Feb 1994<br /><br />
                
                <strong>3. Place of Birth:</strong> <br />
                Mentioning your place of birth provides insight into your roots and cultural background. It can also be significant for astrological purposes and serves as a great conversation starter.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Place of Birth:</strong> New Delhi, Delhi<br /><br />
                
                <strong>4. Rashi (Moon Sign):</strong> <br /> 
                In Vedic astrology, your Rashi or Moon Sign reflects your emotional nature and mindset. It's an important factor for families who prioritize astrological compatibility.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Rashi:</strong> Tula (Libra)<br /><br />
                
                <strong>5. Nakshatra:</strong> <br />
                Nakshatras, or lunar mansions, offer deeper insights into personality traits and life themes. If astrology is important to your family, include this detail. Otherwise, feel free to skip it.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Nakshatra:</strong> Anuradha<br /><br />
                
                <strong>6. Height:</strong> <br />
                Height is a common physical attribute that many consider during matchmaking. Including it can help provide a complete picture of your profile.<br /><br />
                <strong>7. Gotra:</strong> Gotra represents ancestral lineage and is significant in many Indian communities for assessing family compatibility. If this is relevant to your culture, include it. Otherwise, you can omit this section.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Gotra:</strong> Kshatriya <br /><br />
                
                <strong>8. Educational Background:</strong> <br />
                Your education reflects your lifestyle, values, and aspirations. Sharing this information helps potential matches understand your mindset and future goals.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Education:</strong> MBA from IIM Mumbai, B.Tech from IIT Delhi<br /><br />
                
                <strong>9. Work Details:</strong> <br />
                Your professional background provides insight into your career, financial stability, and achievements. This is an important factor for building a secure future together.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Work Details:</strong> Senior Software Engineer at Google, specializing in Cloud Computing<br /><br />
                
                <strong>10. Family Details:</strong> <br />
                Sharing information about your family helps potential matches understand your support system and values. Keep this section brief and positive.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Father's Name:</strong> Rohan Mittal<br />
                <strong>Father's Occupation:</strong> Retired Businessman<br />
                <strong>Siblings:</strong> One younger brother, Anupam Mittal, pursuing MBBS at AIIMS Delhi<br /><br />
                
                <strong>11. Hobbies and Interests:</strong> <br />
                Including hobbies makes your biodata more relatable and engaging. It also highlights your personality and shared interests, which can help build a stronger connection.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Hobbies:</strong> Traveling, Singing, Cooking, Reading<br /><br />
                
                <strong>12. Personal Values and Expectations:</strong> <br />
                This section allows you to express what you value in a partner and marriage. Be honest and clear about your expectations for better compatibility.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Personal Values and Expectations:</strong> I value honesty, mutual respect, and a strong sense of family. I'm looking for a partner who shares similar values and enjoys exploring new places.<br /><br />
                
                <strong>13. Contact Details:</strong> <br />
                Provide contact information for a family member who can facilitate discussions or meetings. Ensure the contact person is approachable and can communicate effectively.<br />
                <br />
                <span style={{ color: '#006f80' }}>For example:</span> <br />
                <strong>Contact Details:</strong> +91 9854101234<br />
                <strong>Email:</strong> rohanmittal@gmail.com<br /><br />
                
                <strong>14. Photograph:</strong> <br />
                A recent, high-quality photograph adds a personal touch to your biodata. Choose a professional yet natural image that reflects your personality.<br />
                <br />
             
                <strong>Pro Tip:</strong> <br />
                Keep your biodata authentic and concise. Avoid unnecessary details and focus on presenting your true self. Whether you're traditional, modern, or a blend of both, tailor your biodata to reflect your unique story and values.<br />
          
                <br />
                By including these details, you will create a well-rounded and appealing marriage biodata that leaves a lasting impression on potential matches.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ position: 'sticky', top: 0 }}>
                <img src="/imgs/sampleBio/eg1.png" alt="Essential details" style={{ width: '100%', height: 'auto' }} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="faq" style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" component="h2" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I create a biodata?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can create a biodata by filling out the required fields in our app and customizing it according to your preferences.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Can I customize my biodata?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, our app allows you to customize your biodata with various templates and options to suit your needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Is there a limit to the number of fields I can add?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                There is no strict limit, but we recommend keeping it concise to ensure clarity and readability.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Can I upload my own photos?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can upload your own photos to personalize your biodata.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I choose a template for my biodata?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can choose a template during the biodata creation process, where you can preview and select from various designs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Is my data secure and private?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, we prioritize your privacy and security, ensuring that your data is protected and not shared without your consent.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Add more questions as needed */}
        </Container>
      </div>

      <div className="call-to-action" style={{ padding: '20px', backgroundColor: 'rgb(243 199 251)' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" sx={{ pt: 4, pb: 4 }} gutterBottom>
            <strong>One important step in your search for the ideal life partner is creating a marriage biodata.</strong>
          </Typography>
          <Typography variant="h4" color="h2" sx={{ pt: 1, pb: 4 }}>Begin your journey with us right now! 
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
            <button 
              className="cta-button" 
              onClick={() => formRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              Create My Biodata
            </button>
          </div>
        </Container>
      </div>

      <Footer />

      {/* Template Selection Modal for Desktop */}
      {isMobile ? null : (
        <TemplateSelection
          open={isTemplateModalOpen}
          onClose={handleCloseTemplateModal}
          formData={formData}
          preloadedTemplates={preloadedTemplates}
          isTemplatesLoading={isTemplatesLoading}
        />
      )}

      {/* Template Selection Modal for Mobile */}
      {isMobile ? (
        <TemplateSelectionMobile
          open={isTemplateModalOpen}
          onClose={handleCloseTemplateModal}
          formData={formData}
          preloadedTemplates={preloadedTemplates}
          isTemplatesLoading={isTemplatesLoading}
        />
      ) : null}

      <Dialog open={openPhotoDialog} onClose={() => setOpenPhotoDialog(false)}>
        <DialogTitle>Profile Photo Required</DialogTitle>
        <DialogContent>
          <p>Please upload a profile photo before choosing a template.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPhotoDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Add this new component for protected routes
function ProtectedRoute({ children }) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthenticated(!!user);
      setLoading(false);
      if (!user) {
        // Add state to preserve the intended destination
        navigate('/login', { state: { from: window.location.pathname } });
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return authenticated ? children : null;
}

// Update the Routes section in AppWrapper component
function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AnalyticsRoute><App /></AnalyticsRoute>} />
          <Route path="/blog" element={<AnalyticsRoute><BlogList /></AnalyticsRoute>} />
          <Route path="/blog/:slug" element={<AnalyticsRoute><BlogPost /></AnalyticsRoute>} />
          <Route path="/terms-of-service" element={<AnalyticsRoute><TermsOfService /></AnalyticsRoute>} />
          <Route path="/privacy-policy" element={<AnalyticsRoute><PrivacyPolicy /></AnalyticsRoute>} />
          <Route path="/refund-policy" element={<AnalyticsRoute><RefundPolicy /></AnalyticsRoute>} />
          <Route path="/contact-us" element={<AnalyticsRoute><ContactUs /></AnalyticsRoute>} />
          <Route path="/shipping-and-delivery-policy" element={<AnalyticsRoute><ShippingAndDeliveryPolicy /></AnalyticsRoute>} />
          <Route path="/login" element={<AnalyticsRoute><Login /></AnalyticsRoute>} />
          <Route path="/biodata-download" element={<AnalyticsRoute><TemplateDownload /></AnalyticsRoute>} />
          <Route 
            path="/template-editor" 
            element={
              <AnalyticsRoute>
                <ProtectedRoute>
                  <TemplateEditor />
                </ProtectedRoute>
              </AnalyticsRoute>
            } 
          />
          <Route path="*" element={<AnalyticsRoute><Navigate to="/" replace /></AnalyticsRoute>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default AppWrapper;
