import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

function loadRazorpay() {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
}

function Payment({ template, formData, onSuccess, onError, onInit, onClose }) {
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const functions = getFunctions();

  const handlePayment = async () => {
    setLoading(true);
    // Call onInit to signal payment is being initiated
    if (onInit) onInit();
    
    const createOrderFunction = httpsCallable(functions, 'createOrder');
    
    try {
      const isLoaded = await loadRazorpay();
      if (!isLoaded) {
        if (onClose) onClose();
        throw new Error('Razorpay SDK failed to load');
      }

      // Create order using callable function
      const { data: { order } } = await createOrderFunction({
        amount: template.pricing.regions.IN.discountedPrice,
        currency: 'INR',
        receipt: `rcpt_${Date.now()}`,
        templateId: template.id
      });

      // Sanitize form data to ensure it can be processed by Firebase
      const sanitizedFormData = JSON.parse(JSON.stringify(formData));

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: 'Modern Shaadi',
        description: `Template: ${template.templateName}`,
        order_id: order.id,
        handler: async function (response) {
          try {
            const verifyPaymentFunction = httpsCallable(functions, 'verifyPayment');
            const { data } = await verifyPaymentFunction({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              formData: sanitizedFormData,
              templateName: template.templateName
            });
            
            if (data.verified) {
              onSuccess(response);
            } else {
              if (onClose) onClose();
              throw new Error('Payment verification failed');
            }
          } catch (error) {
            console.error('Payment verification error:', error);
            if (onClose) onClose();
            onError(error);
          }
        },
        prefill: {
          name: auth.currentUser?.displayName,
          email: auth.currentUser?.email,
        },
        theme: {
          color: '#007bff'
        },
        modal: {
          ondismiss: function() {
            if (onClose) onClose();
            setLoading(false);
          }
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      if (onClose) onClose();
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePayment}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        {loading ? 'Processing...' : 'Buy Now'}
      </Button>
    </Box>
  );
}

export default Payment; 