import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBlogClick = () => {
    navigate('/blog');
    handleClose();
  };

  const navbarClass = window.location.pathname === '/' ? 'navbar' : 'navbar-others';

  return (
    <nav className={navbarClass}>
      <Container maxWidth="lg" sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        width: '100%'
      }}>
        {/* <div style={{ flex: 1 }}></div> Spacer for centering */}
        {isMobile ? (
          <>
           <div style={{flex: 1}}></div>
          </>
        ) : null}
        <img 
          src="/imgs/logo.png" 
          alt="Logo" 
          className="logo" 
          onClick={() => navigate('/')} 
          style={{ cursor: 'pointer' }}
        />
        
        <div style={{ 
          flex: 1, 
          display: 'flex', 
          justifyContent: 'flex-end' 
        }}>
          {isMobile ? (
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="mobile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleBlogClick}>Blog</MenuItem>
              </Menu>
            </>
          ) : (
            <Button 
              sx={{ 
                backgroundColor: '#ff3635', 
                fontWeight: 600, 
                padding: '0.5rem 1.5rem', 
                borderRadius: '8px', 
                height: '40px', 
                minWidth: '100px', 
                textTransform: 'none', 
                fontSize: '1rem' 
              }} 
              variant="contained"
              onClick={() => navigate('/blog')}
            >
              Blog
            </Button>
          )}
        </div>
      </Container>
    </nav>
  );
};

export default Navbar; 