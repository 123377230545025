import React, { useState, useEffect } from 'react';
import { Box, Container, FormControl, InputLabel, MenuItem, Select, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { JsonEditor } from 'json-edit-react';
import { firestore, storage } from '../services/firebaseConfig';
import { collection, getDocs, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import * as TemplateHelper from './TemplateSelectionHelper';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import templateData from '../assets/template.json';

function TemplateEditor() {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateConfig, setTemplateConfig] = useState(null);
  const [sampleBios, setSampleBios] = useState([]);
  const [selectedBio, setSelectedBio] = useState(null);
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    loadTemplates();
    loadSampleBios();
  }, []);

  const loadTemplates = async () => {
    try {
      const fetchTemplates = TemplateHelper.fetchTemplatesFromFirestore(true);
      const templatesData = await fetchTemplates();
      setTemplates(templatesData);
    } catch (error) {
      console.error('Error loading templates:', error);
    }
  };

  const loadSampleBios = async () => {
    try {
      const biosCollection = collection(firestore, 'sampleBio');
      const biosSnapshot = await getDocs(biosCollection);
      const biosData = biosSnapshot.docs.map(doc => {
        const bio = { id: doc.id, ...doc.data() };

        // Reorder bioData according to the specified structure
        const orderedBioData = {
          id: doc.id,
          'Personal Details': {
            'Name': bio['Personal Details']?.Name,
            'Date of Birth': bio['Personal Details']?.['Date of Birth'],
            'Place of Birth': bio['Personal Details']?.['Place of Birth'],
            'Time of Birth': bio['Personal Details']?.['Time of Birth'],
            'Rashi': bio['Personal Details']?.Rashi,
            'Nakshatra': bio['Personal Details']?.Nakshatra,
            'Complexion': bio['Personal Details']?.Complexion,
            'Height': bio['Personal Details']?.Height,
            'Gotra': bio['Personal Details']?.Gotra,
            'Gender': bio['Personal Details']?.Gender,
            'Education': bio['Personal Details']?.Education,
            'Occupation': bio['Personal Details']?.Occupation,
          },
          'Family Details': {
            'Father\'s Name': bio['Family Details']?.['Father\'s Name'],
            'Father\'s Occupation': bio['Family Details']?.['Father\'s Occupation'],
            'Mother\'s Name': bio['Family Details']?.['Mother\'s Name'],
            'Mother\'s Occupation': bio['Family Details']?.['Mother\'s Occupation'],
            'Siblings': bio['Family Details']?.Siblings,
          },
          'Contact Details': {
            'Contact Person': bio['Contact Details']?.['Contact Person'],
            'Phone': bio['Contact Details']?.Phone,
            'Email': bio['Contact Details']?.Email,
            'Address': bio['Contact Details']?.Address,
          },
          profilePhoto: bio.profilePhoto
        };

        return orderedBioData;
      });

      setSampleBios(biosData);
    } catch (error) {
      console.error('Error loading sample bios:', error);
    }
  };

  const handleTemplateChange = (event) => {
    const template = templates.find(t => t.id === event.target.value);
    setSelectedTemplate(template);
    
    const config = {
      templateName: template.templateName,
      isPublished: template.isPublished,
      templateIcon: template.templateIcon,
      globalStyles: template.globalStyles,
      pages: template.pages,
      pricing: template.pricing
    };
    setTemplateConfig(config);

    try {
      TemplateHelper.drawTemplateOnCanvas('templateCanvas', template.templateUrl, template, selectedBio);
    } catch (error) {
      console.error('Error drawing template:', error);
      // Optionally show an error message to the user
    }
  };

  const handleBioChange = async (event) => {
    const bio = sampleBios.find(b => b.id === event.target.value);
    if (!bio) return;

    // Remove id from bio
    const { id, ...bioData } = bio;
    // Fetch profile photo from storage
    if (bioData.profilePhoto) {
      try {
        const profilePhotoRef = ref(storage, bioData.profilePhoto);
        const profilePhotoUrl = await getDownloadURL(profilePhotoRef);
        bioData.profilePhoto = profilePhotoUrl;
      } catch (error) {
        console.error('Error fetching profile photo:', error);
      }
    }

    setSelectedBio(bioData);

    if (selectedTemplate) {
      TemplateHelper.drawTemplateOnCanvas('templateCanvas', selectedTemplate.templateUrl, selectedTemplate, bioData);
    }
  };

  const handleConfigChange = (newConfig) => {
    setTemplateConfig(newConfig);
    
    if (selectedTemplate) {
      const updatedTemplate = {
        ...selectedTemplate,
        ...newConfig
      };
      TemplateHelper.drawTemplateOnCanvas('templateCanvas', selectedTemplate.templateUrl, updatedTemplate, selectedBio);
    }
  };

  const handleSaveConfig = async () => {
    if (!selectedTemplate || !templateConfig) return;

    try {
      const templateRef = doc(firestore, 'templates', selectedTemplate.id);
      await updateDoc(templateRef, templateConfig);
      alert('Template configuration saved successfully!');
      loadTemplates();
    } catch (error) {
      console.error('Error saving template configuration:', error);
      alert('Error saving template configuration');
    }
  };

  const blankTemplateConfig = templateData;

  const handleCreateTemplate = async () => {
    try {
      const templatesRef = collection(firestore, 'templates');
      const newTemplateDoc = await addDoc(templatesRef, {
        ...blankTemplateConfig,
        templateUrl: "",
        createdAt: new Date(),
        updatedAt: new Date()
      });

      const newTemplate = {
        id: newTemplateDoc.id,
        ...blankTemplateConfig,
        templateUrl: "",
      };

      setTemplates(prevTemplates => [...prevTemplates, newTemplate]);
      
      setSelectedTemplate(newTemplate);
      setTemplateConfig(blankTemplateConfig);

    } catch (error) {
      console.error('Error creating new template:', error);
    }
  };

  const handleDeleteTemplate = async () => {
    if (!selectedTemplate) return;

    try {
      // Delete from Firestore
      const templateRef = doc(firestore, 'templates', selectedTemplate.id);
      await deleteDoc(templateRef);

      // Update local state
      setTemplates(prevTemplates => 
        prevTemplates.filter(template => template.id !== selectedTemplate.id)
      );

      // Reset selections
      setSelectedTemplate(null);
      setTemplateConfig(null);

      // Close dialog
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting template:', error);
      alert('Error deleting template');
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Select Sample Bio</InputLabel>
          <Select
            value={selectedBio?.id || ''}
            onChange={handleBioChange}
            label="Select Sample Bio"
          >
            {sampleBios.map((bio) => (
              <MenuItem key={bio.id} value={bio.id}>
                {bio['Personal Details']?.Name || 'Unnamed Bio'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedBio && (
          <Typography variant="h6" gutterBottom>
            Selected Bio: {selectedBio['Personal Details']?.Name || 'N/A'}
          </Typography>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <FormControl fullWidth>
            <InputLabel>Select Template</InputLabel>
            <Select
              value={selectedTemplate?.id || ''}
              onChange={handleTemplateChange}
              label="Select Template"
              disabled={!selectedBio}
            >
              {templates.map((template) => (
                <MenuItem 
                  key={template.id} 
                  value={template.id}
                  sx={template.hasError ? { color: 'warning.main' } : {}}
                >
                  {template.templateName}
                  {template.hasError && ' (Some images may not load)'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateTemplate}
            sx={{ ml: 2, minWidth: 'auto', whiteSpace: 'nowrap' }}
          >
            New Template
          </Button>

          {selectedTemplate && (
            <IconButton
              color="error"
              onClick={() => setDeleteDialogOpen(true)}
              sx={{ ml: 1 }}
              title="Delete Template"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>

        <Box sx={{ 
          display: 'flex', 
          gap: 4, 
          height: 'calc(100vh - 200px)'
        }}>
          <Box sx={{ 
            flex: 1, 
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: '#f5f5f5'
          }}>
            <canvas
              id="templateCanvas"
              width={595}
              height={842}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>

          <Box sx={{ 
            flex: 1, 
            border: '1px solid #ccc',
            overflow: 'auto',
            p: 2
          }}>
            {templateConfig && (
              <>
                <JsonEditor
                  data={templateConfig}
                  setData={handleConfigChange}
                  expandButtonStyle={{
                    color: '#1976d2',
                    backgroundColor: 'transparent'
                  }}
                  inputStyle={{
                    fontSize: '14px',
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ccc'
                  }}
                  keyInputStyle={{
                    fontSize: '14px',
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    marginRight: '8px'
                  }}
                  textareaStyle={{
                    fontSize: '14px',
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ccc'
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveConfig}
                  sx={{ mt: 2 }}
                >
                  Save Configuration
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Delete Template</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete "{selectedTemplate?.templateName}"? 
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setDeleteDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleDeleteTemplate}
              color="error"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

export default TemplateEditor; 