import React from 'react';
import { Container, Typography } from '@mui/material';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const ShippingAndDeliveryPolicy = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Typography variant="h4" align="center" gutterBottom>Shipping and Delivery Policy</Typography>
        <Typography variant="body1">
        All Marriage Biodata Template that are offered for sale on ModernShaadi.com a digital products that can only be downloaded digitally. For these digital publications, no copies or physical goods are shipped.
        <br />
        <br />
        Once the payment has been verified, your file(s) will be automatically downloaded to your device; however, you can download them again by selecting the "Download PDF" button on the "Download" page. After the checkout process is finished and payment is accepted, the user or users will have the choice to download. You are in charge of keeping the file safe after you download the one you paid for.
        <br />
        <br />
        If you encounter any technical issues preventing you from downloading the file after successful payment, please reach out to us at <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a>, and we will email you the file after verifying your payment status.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default ShippingAndDeliveryPolicy; 