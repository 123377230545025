import { firestore, storage } from '../services/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

// Make sure to declare these as regular functions, not arrow functions
export function fetchTemplatesFromFirestore(showAllTemplates = false) {
  return async () => {
    try {
      const templatesCollection = collection(firestore, 'templates');
      const templatesSnapshot = await getDocs(templatesCollection);
      
      const templates = await Promise.allSettled(
        templatesSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          
          // Skip templates that aren't published unless showAllTemplates is true
          if (!showAllTemplates && data.isPublished === false) {
            return null;
          }
          
          try {
            // Get URLs from Storage references, handle missing references
            const iconUrl = data.templateIcon ? 
              await getDownloadURL(ref(storage, data.templateIcon)) : null;
            
            const page1Data = data.pages.page1;
            const previewUrl = page1Data.pagePreviewSrc ? 
              await getDownloadURL(ref(storage, page1Data.pagePreviewSrc)) : null;
            const templateUrl = page1Data.pageSrc ? 
              await getDownloadURL(ref(storage, page1Data.pageSrc)) : null;
            
            return {
              id: doc.id,
              ...data,
              iconUrl,
              previewUrl,
              templateUrl,
              hasError: false
            };
          } catch (error) {
            console.warn(`Error fetching URLs for template ${doc.id}:`, error);
            return {
              id: doc.id,
              ...data,
              hasError: true,
              errorMessage: 'Some images failed to load'
            };
          }
        })
      );
      
      // Filter out rejected promises, null values, and handle fulfilled ones
      return templates
        .map(result => {
          if (result.status === 'fulfilled') {
            return result.value;
          }
          console.error('Template fetch rejected:', result.reason);
          return null;
        })
        .filter(template => template !== null);
        
    } catch (error) {
      console.error('Error fetching templates:', error);
      throw error;
    }
  };
}

function wrapText(ctx, text, x, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = ctx.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

export const drawTemplateOnCanvas = async (canvasId, templateUrl, template, formData, canvasElement = null, selectedFont = null) => {
  // Get canvas either by ID or directly from parameter
  const canvas = canvasElement || document.getElementById(canvasId);
  if (!canvas) throw new Error('Canvas not found');
  
  const ctx = canvas.getContext('2d');
  
  // Enable image smoothing for better quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';
  
  // Create a new image with crossOrigin set
  const templateImage = new Image();
  templateImage.crossOrigin = 'anonymous';
  
  try {
    // Clear the canvas first
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Load the image using a promise
    await new Promise((resolve, reject) => {
      templateImage.onload = resolve;
      templateImage.onerror = () => reject(new Error('Failed to load template image'));
      
      // Append cache-busting parameter to bypass browser cache
      const cacheBuster = `?timestamp=${Date.now()}`;
      templateImage.src = `${templateUrl}${cacheBuster}`;
    });

    // Draw the template image to fit the canvas
    ctx.drawImage(
      templateImage, 
      0, 0, templateImage.width, templateImage.height,
      0, 0, canvas.width, canvas.height
    );
    
    // Draw form data on canvas, passing the selected font
    drawContent(ctx, template, formData, canvas, canvas.width / 595, selectedFont); // 595 is base A4 width in points
    
  } catch (error) {
    throw new Error(`Error drawing template: ${error.message}`);
  }
};

function drawContent(ctx, template, formData, canvas, scale, selectedFont) {
  if (!template || !formData) return;
  
  // Apply scale transform
  ctx.scale(scale, scale);
  
  // Sort pages by order
  const pages = Object.entries(template.pages)
    .sort((a, b) => a[1].order - b[1].order);

  // Use the selected font or fall back to the template's default font
  const fontFamily = selectedFont || template.globalStyles.fontFamily;

  pages.forEach(([pageKey, pageData]) => {
    const { profileImage, profileHero } = pageData.drawableRegions;
    
    // Draw profile photo if exists and profile image region is defined
    if (formData.profilePhoto && profileImage && profileImage.enabled) {
      // Create a new image and wait for it to load
      const profileImg = new Image();
      profileImg.crossOrigin = 'anonymous'; // Handle CORS
      profileImg.src = formData.profilePhoto;
      
      // We need to ensure the image is loaded before drawing
      if (profileImg.complete) {
        // Image is already loaded (from cache)
        drawProfileImage(ctx, profileImg, profileImage);
      } else {
        // Set up onload handler
        profileImg.onload = () => {
          drawProfileImage(ctx, profileImg, profileImage);
        };
        
        profileImg.onerror = () => {
          console.warn('Failed to load profile photo');
          // Optionally draw a placeholder or leave space empty
        };
      }
    }
    
    // Draw profile hero if defined, passing the custom font
    if (profileHero && profileHero.enabled) {
      drawProfileHero(ctx, profileHero, formData, template, fontFamily);
    }
    
    // Pass the selected font to the text content drawing function
    drawTextContent(ctx, template, formData, canvas, fontFamily);
  });
}

function drawProfileImage(ctx, profileImg, profileImage) {
  const { x, y, width, height, type, radius, border, shadow } = profileImage;
  
  ctx.save();
  
  try {
    // Enable high-quality image rendering
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    
    // Apply shadow if specified
    if (shadow) {
      ctx.shadowColor = shadow.color;
      ctx.shadowBlur = shadow.blur;
      ctx.shadowOffsetX = shadow.offsetX;
      ctx.shadowOffsetY = shadow.offsetY;
    }
    
    // Create clipping path
    ctx.beginPath();
    if (type === 'circle') {
      ctx.arc(x + width/2, y + height/2, radius, 0, Math.PI * 2);
    } else {
      ctx.rect(x, y, width, height);
    }
    ctx.clip();
    
    // Draw the profile image
    ctx.drawImage(profileImg, x, y, width, height);
    
    // Draw border if specified
    if (border) {
      ctx.strokeStyle = border.color;
      ctx.lineWidth = border.width;
      if (type === 'circle') {
        ctx.arc(x + width/2, y + height/2, radius, 0, Math.PI * 2);
      } else {
        ctx.rect(x, y, width, height);
      }
      ctx.stroke();
    }
  } catch (error) {
    console.warn('Error drawing profile image:', error);
  }
  
  ctx.restore();
}

function drawProfileHero(ctx, profileHero, formData, template, customFont) {
  const { position, styles, headerFormFeild, subFormFeild } = profileHero;
  
  ctx.save();
  
  // Draw header field
  const headerFieldData = formData[headerFormFeild.fieldPath.split('.')[0]][headerFormFeild.fieldPath.split('.')[1]];
  if (headerFieldData) {
    // Use the custom font or fall back to the style-specific font or template default
    const fontFamily = customFont || styles.headerFormFeild.fontFamily || template.globalStyles.fontFamily;
    ctx.font = `${styles.headerFormFeild.fontStyle} ${styles.headerFormFeild.fontWeight} ${styles.headerFormFeild.fontSize}px ${fontFamily}`;
    ctx.fillStyle = styles.headerFormFeild.fontColor;
    ctx.textAlign = styles.headerFormFeild.textAlignment;
    
    // Check if a field label is available
    const headerLabel = headerFormFeild.feildLabel ? `${headerFormFeild.feildLabel}: ` : '';
    ctx.fillText(`${headerLabel}${headerFieldData}`, position.x, position.y);
  }
  
  // Draw sub fields
  subFormFeild.forEach((field) => {
    const fieldData = formData[field.fieldPath.split('.')[0]][field.fieldPath.split('.')[1]];
    if (fieldData) {
      // Use the custom font or fall back to the style-specific font or template default
      const fontFamily = customFont || styles.subFormFeild.fontFamily || template.globalStyles.fontFamily;
      ctx.font = `${styles.subFormFeild.fontStyle} ${styles.subFormFeild.fontWeight} ${styles.subFormFeild.fontSize}px ${fontFamily}`;
      ctx.fillStyle = styles.subFormFeild.fontColor;
      ctx.textAlign = styles.subFormFeild.textAlignment;
      
      // Check if a field label is available
      const subFieldLabel = field.feildLabel ? `${field.feildLabel}: ` : '';
      ctx.fillText(`${subFieldLabel}${fieldData}`, position.x, position.y + (styles.subFormFeild.fontSize * field.order));
    }
  });
  
  ctx.restore();
}

function drawTextContent(ctx, template, formData, canvas, customFont) {
  if (!template || !formData) return;
  
  const page1 = template.pages.page1;
  const sections = page1.sections;
  
  // Sort sections by order
  const orderedSections = Object.entries(sections)
    .filter(([_, config]) => config.enabled)
    .sort((a, b) => a[1].order - b[1].order);
  
  // Use the custom font or fall back to the template default
  const fontFamily = customFont || template.globalStyles.fontFamily;
  
  // First pass: calculate the maximum label width across ALL sections
  let globalMaxLabelWidth = 0;
  
  orderedSections.forEach(([sectionTitle, sectionConfig]) => {
    const sectionData = formData[sectionTitle];
    if (!sectionData) return;
    
    const { disabledFields } = sectionConfig;
    const { content } = sectionConfig.styles;
    
    // Set font for measuring text, using the custom font
    ctx.font = `${content.label.fontStyle} ${content.label.fontWeight} ${content.fontSize}px ${fontFamily}`;
    
    // Measure all labels in this section
    Object.keys(sectionData).forEach(key => {
      if (!disabledFields || !disabledFields.includes(key)) {
        const labelWidth = ctx.measureText(`${key}: `).width;
        globalMaxLabelWidth = Math.max(globalMaxLabelWidth, labelWidth);
      }
    });
  });

  // Use the global fieldGap from template.globalStyles.spacing
  const fieldGap = template.globalStyles.spacing.fieldGap;
  const globalValueOffset = globalMaxLabelWidth + fieldGap;
  
  // Second pass: draw all sections using the global max width
  orderedSections.forEach(([sectionTitle, sectionConfig]) => {
    const sectionData = formData[sectionTitle];
    if (!sectionData) return;
    
    const { position, styles, disabledFields } = sectionConfig;
    const { header, content } = styles;
    
    // Draw section header
    let transformedTitle = sectionTitle;
    if (header.textTransform === 'uppercase') {
      transformedTitle = sectionTitle.toUpperCase();
    } else if (header.textTransform === 'capitalize') {
      transformedTitle = sectionTitle.replace(/\b\w/g, char => char.toUpperCase());
    } else if (header.textTransform === 'lowercase') {
      transformedTitle = sectionTitle.toLowerCase();
    }
    
    let currentY = position.y + (header.padding?.top || 0);
    
    // Draw header background if enabled
    if (header.showBackground && header.backgroundColor) {
      const headerHeight = header.fontSize + (header.padding?.top || 0) + (header.padding?.bottom || 0);
      ctx.fillStyle = header.backgroundColor;
      ctx.beginPath();
      ctx.roundRect(
        position.x,
        currentY,
        header.headerWidth || position.width,
        headerHeight,
        header.borderRadius
      );
      ctx.fill();
    }
    
    // Draw header text using the custom font
    ctx.font = `${header.fontStyle} ${header.fontWeight} ${header.fontSize}px ${fontFamily}`;
    ctx.fillStyle = header.fontColor;
    ctx.textAlign = header.textAlignment;
    
    // Calculate x position based on text alignment
    let headerX = position.x + (header.padding?.left || 0);
    if (header.textAlignment === 'center') {
      headerX = position.x + (header.headerWidth || position.width) / 2;
    } else if (header.textAlignment === 'right') {
      headerX = position.x + (header.headerWidth || position.width) - (header.padding?.right || 0);
    }
    
    // Calculate vertical center position for text
    const textY = currentY + (header.fontSize + (header.padding?.top || 0) + (header.padding?.bottom || 0)) / 2 + (header.fontSize / 3);
    
    ctx.fillText(
      transformedTitle,
      headerX,
      textY
    );
    
    // Reset text alignment for content
    ctx.textAlign = content.textAlignment;
    
    currentY += header.fontSize + (header.padding?.bottom || 0) + (header.margin?.bottom || 0);
    
    // Draw fields using the global value offset
    Object.entries(sectionData).forEach(([key, value]) => {
      if (value && currentY < (position.y + position.height) && (!disabledFields || !disabledFields.includes(key))) {
        // Draw label using the custom font
        ctx.font = `${content.label.fontStyle} ${content.label.fontWeight} ${content.fontSize}px ${fontFamily}`;
        ctx.fillStyle = content.label.fontColor;
        ctx.fillText(`${key}:`, position.x, currentY);
        
        // Draw value with wrapping using the custom font
        ctx.font = `${content.fontStyle} ${content.fontWeight} ${content.fontSize}px ${fontFamily}`;
        ctx.fillStyle = content.fontColor;
        const maxWidth = position.width - globalValueOffset;
        const lines = wrapText(ctx, value.toString(), position.x + globalValueOffset, maxWidth);
        
        lines.forEach((line) => {
          ctx.fillText(line, position.x + globalValueOffset, currentY);
          currentY += content.fontSize * content.lineHeight;
        });
      }
    });
  });
}
