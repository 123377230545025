import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from '@mui/material';
import Slider from 'react-slick';
import { storage } from '../services/firebaseConfig';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as TemplateHelper from './TemplateSelectionHelper';
import * as Analytics from '../services/analyticsService';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Payment from './Payment';
import { useNavigate, Link } from 'react-router-dom';
import jsPDF from 'jspdf';

function TemplateSelectionMobile({ open, onClose, formData, preloadedTemplates = [], isTemplatesLoading = true }) {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedFont, setSelectedFont] = useState('Noto Sans Display'); // Default font
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false); // New state for Terms dialog
  const [isCanvasLoading, setIsCanvasLoading] = useState(false); // New state for canvas loading
  const navigate = useNavigate();

  useEffect(() => {
    // Use preloaded templates if available, otherwise load them
    if (preloadedTemplates.length > 0) {
      setTemplates(preloadedTemplates);
      setLoading(false); // Always set loading to false immediately when using preloaded templates
    } else {
      const loadTemplates = async () => {
        try {
          setLoading(true);
          const fetchTemplates = TemplateHelper.fetchTemplatesFromFirestore();
          const templatesData = await fetchTemplates();
          
          // Sort templates - free templates first
          const sortedTemplates = [...templatesData].sort((a, b) => {
            const aIsFree = a.pricing?.regions?.IN?.isFree || false;
            const bIsFree = b.pricing?.regions?.IN?.isFree || false;
            if (aIsFree && !bIsFree) return -1;
            if (!aIsFree && bIsFree) return 1;
            return 0;
          });
          
          setTemplates(sortedTemplates);
        } catch (error) {
          console.error('Error loading templates:', error);
          Analytics.trackError('template_loading_error', error.message, 'TemplateSelectionMobile');
        } finally {
          setLoading(false);
        }
      };

      loadTemplates();
    }
    
    // Track modal open event
    if (open) {
      Analytics.trackModalOpen('template_selection_mobile');
      
      // Calculate form completion percentage
      const completionPercentage = calculateFormCompletion(formData);
      Analytics.trackFormPartialCompletion(completionPercentage);
    }
  }, [open, preloadedTemplates, isTemplatesLoading, formData]);

  // Calculate form completion percentage
  const calculateFormCompletion = (data) => {
    if (!data) return 0;
    
    // Count fields that have data
    let filledFields = 0;
    let totalFields = 0;
    
    Object.keys(data).forEach(key => {
      // Skip fields that aren't actual form fields
      if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null) {
        // It's a section, recurse into it
        Object.keys(data[key]).forEach(subKey => {
          totalFields++;
          if (data[key][subKey] && data[key][subKey] !== '') {
            filledFields++;
          }
        });
      } else {
        totalFields++;
        if (data[key] && data[key] !== '') {
          filledFields++;
        }
      }
    });
    
    return (filledFields / totalFields) * 100;
  };

  useEffect(() => {
    const canvas = document.getElementById('templateCanvas');
    if (canvas) {
      // Disable right-click menu on canvas
      canvas.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });

      // Disable copy command
      canvas.addEventListener('copy', (e) => {
        e.preventDefault();
      });

      // Disable drag start
      canvas.addEventListener('dragstart', (e) => {
        e.preventDefault();
      });
    }
  }, []);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setIsCanvasLoading(true);
    
    // Track template selection
    Analytics.trackTemplateView(template.id, template.templateName);
    
    // Get the price
    const price = getPriceFromTemplate(template);
    
    TemplateHelper.drawTemplateOnCanvas('templateCanvas', template.previewUrl, template, formData, null, selectedFont)
      .then(() => setIsCanvasLoading(false))
      .catch((error) => {
        setIsCanvasLoading(false);
        Analytics.trackError('template_preview_error', error.message, 'TemplateSelectionMobile');
      });
  };

  // Helper to get template price
  const getPriceFromTemplate = (template) => {
    if (!template || !template.pricing) return 0;
    
    const pricing = template.pricing;
    const defaultRegion = 'IN';
    const regionPricing = pricing.regions[defaultRegion];
    
    if (!regionPricing || !regionPricing.isActive) return 0;
    
    if (regionPricing.isFree) return 0;
    
    return regionPricing.discountedPrice || regionPricing.price || 0;
  };

  // Add font change handler
  const handleFontChange = (event) => {
    const newFont = event.target.value;
    setSelectedFont(newFont);
    
    // Track font selection
    Analytics.trackFontSelection(newFont, selectedTemplate?.id);
    
    // Redraw canvas with the new font immediately
    if (selectedTemplate) {
      TemplateHelper.drawTemplateOnCanvas('templateCanvas', selectedTemplate.previewUrl, selectedTemplate, formData, null, newFont);
    }
  };

  const handleDownload = () => {
    console.log('handleDownload clicked', selectedTemplate);
    
    if (!selectedTemplate) {
      alert('Please select a template first');
      return;
    }
    
    const isFree = selectedTemplate.pricing?.regions?.IN?.isFree || false;
    console.log('Template is free:', isFree);
    
    // Track template selection for download
    const price = getPriceFromTemplate(selectedTemplate);
    Analytics.trackTemplateSelection(selectedTemplate.id, selectedTemplate.templateName, isFree ? 0 : price);
    
    if (isFree) {
      // Track free download initiated
      Analytics.trackEvent('Conversion', 'free_download_initiated', selectedTemplate.templateName);
      
      // For free templates, generate PDF from canvas
      const canvas = document.getElementById('templateCanvas');
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgWidth = 595; // A4 width in pt
      const pageHeight = 841; // A4 height in pt
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${selectedTemplate.templateName}.pdf`);
      
      // Track successful free download
      Analytics.trackDownload('pdf', `${selectedTemplate.templateName}.pdf`, true);
    } else {
      // Show Terms and Conditions dialog for paid templates
      setIsTermsDialogOpen(true);
      
      // Track checkout initiation
      Analytics.trackCheckoutStart(selectedTemplate.id, selectedTemplate.templateName, price);
    }
  };

  const handleTermsAgree = () => {
    setIsTermsDialogOpen(false);
    handlePaymentInitiated();
    
    // Track terms acceptance
    Analytics.trackEvent('Checkout', 'terms_accepted', selectedTemplate?.templateName);
    
    // Find the payment container and trigger the payment
    const paymentContainer = document.getElementById('payment-trigger-container');
    if (paymentContainer) {
      const paymentButton = paymentContainer.querySelector('button');
      if (paymentButton) {
        paymentButton.click();
      } else {
        console.error('Payment button not found inside container');
        setIsPaymentLoading(false);
        Analytics.trackError('payment_button', 'Payment button not found', 'TemplateSelectionMobile');
      }
    } else {
      console.error('Payment container not found');
      setIsPaymentLoading(false);
      Analytics.trackError('payment_container', 'Payment container not found', 'TemplateSelectionMobile');
    }
  };

  const handleTermsClose = () => {
    setIsTermsDialogOpen(false);
    Analytics.trackEvent('Checkout', 'terms_declined', selectedTemplate?.templateName);
  };

  const handlePaymentSuccess = (response, template) => {
    // Stop loading indicator
    setIsPaymentLoading(false);
    
    // Show loading indicator while preparing to redirect
    setIsRedirecting(true);
    
    // Track payment success
    const price = getPriceFromTemplate(template);
    Analytics.trackEvent('Payment', 'payment_success', template.templateName, price, {
      payment_id: response.razorpay_payment_id,
      order_id: response.razorpay_order_id
    });
    
    // Get the price from template pricing data
    const defaultRegion = 'IN';
    const regionPricing = template.pricing?.regions[defaultRegion];
    const templatePrice = regionPricing?.discountedPrice || regionPricing?.price || 0;
    
    navigate('/biodata-download', {
      state: {
        template,
        formData,
        paymentResponse: {
          ...response,
          amount: templatePrice * 100 // Converting to paise/lowest currency unit
        },
        selectedFont
      }
    });
  };

  const handlePaymentInitiated = () => {
    setIsPaymentLoading(true);
    Analytics.trackEvent('Payment', 'payment_initiated', selectedTemplate?.templateName, getPriceFromTemplate(selectedTemplate));
  };

  const handlePaymentClosed = () => {
    setIsPaymentLoading(false);
    Analytics.trackEvent('Payment', 'payment_closed', selectedTemplate?.templateName);
  };

  const handleDialogClose = () => {
    Analytics.trackModalClose('template_selection_mobile_close');
    onClose();
  };

  const renderPrice = (template) => {
    if (!template.pricing) return null;
    
    const pricing = template.pricing;
    const defaultRegion = 'IN';
    const regionPricing = pricing.regions[defaultRegion];
    
    if (!regionPricing || !regionPricing.isActive) return null;

    // For free templates, show "FREE" instead of price
    if (regionPricing.isFree) {
      return (
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          {/* <Typography 
            variant="subtitle1" 
            sx={{ 
              fontSize: '0.5rem',
              fontWeight: 'bold',
              color: 'text.primary',
              mb: 0.5
            }}
          >
            {template.templateName}
          </Typography> */}
          <Typography 
            variant="subtitle1" 
            color="success.main" 
            sx={{ 
              fontWeight: 'bold',
              fontSize: '0.5em',
              ml: 1 
            }}
          >
            FREE
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ mt: 1, textAlign: 'center' }}>
        {/* <Typography 
          variant="subtitle1" 
          sx={{ 
            fontSize: '0.5rem',
            fontWeight: 'bold',
            color: 'text.primary',
            mb: 0.5
          }}
        >
          {template.templateName}
        </Typography> */}

        {regionPricing.discountedPrice < regionPricing.price && (
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ 
              textDecoration: 'line-through',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '0.2em'
            }}
          >
            <CurrencyRupeeIcon sx={{ fontSize: '3em' }} />
            {regionPricing.price}
          </Typography>
        )}
        <Typography 
          variant="subtitle1" 
          color="primary" 
          sx={{ 
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.5em',
            ml: 1 
          }}
        >
          <CurrencyRupeeIcon sx={{ fontSize: '1.4em' }} />
          {regionPricing.discountedPrice}
        </Typography>
      </Box>
    );
  };

  // Available fonts from Google Fonts (defined in index.html)
  const availableFonts = [
    'Cormorant Garamond',
    'Dancing Script',
    'Gloock',
    'Great Vibes',
    'Lavishly Yours',
    'Lora',
    'Montserrat',
    'Noto Sans Display',
    'Parisienne',
    'Prata'
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullScreen>
      {/* Add global loading overlay */}
      {(isPaymentLoading || isRedirecting) && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
          <CircularProgress size={50} thickness={5} sx={{ color: 'white' }} />
          <Typography sx={{ color: 'white', mt: 2 }}>
            {isRedirecting ? 'Redirecting to download page...' : 'Processing payment...'}
          </Typography>
        </Box>
      )}

      <DialogTitle>
        <Box>
          Choose a Template
        </Box>
        <Button onClick={handleDialogClose} color="primary" sx={{ position: 'absolute', right: 16, top: 16 }}>
          Close
        </Button>
      </DialogTitle>
      <DialogContent sx={{ pointerEvents: 'none', pb: 10 }}>
        {/* Font Selection Dropdown - note we set pointerEvents to auto to make it interactive */}
        <Box sx={{ mb: 2, pointerEvents: 'auto', pt: 2 }}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="font-select-label-mobile">Select Font</InputLabel>
            <Select
              labelId="font-select-label-mobile"
              id="font-select-mobile"
              value={selectedFont}
              onChange={handleFontChange}
              label="Select Font"
            >
              {availableFonts.map((font) => (
                <MenuItem key={font} value={font} style={{ fontFamily: font }}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          mb: 2,
          position: 'relative'
        }}>
          {isCanvasLoading && (
            <CircularProgress size={50} sx={{ position: 'absolute', top: '40%', left: '40%', transform: 'translate(-50%, -50%)', zIndex: 2 }} />
          )}
          <canvas 
            id="templateCanvas" 
            width={595}
            height={842}
            style={{ 
              border: '1px solid #000',
              maxWidth: '60%',
              maxHeight: '100%',
              objectFit: 'contain',
              WebkitUserSelect: 'none',
              userSelect: 'none',
              WebkitTouchCallout: 'none',
              pointerEvents: 'none',
            }}
          />
          {/* Protective overlay */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent',
              zIndex: 1,
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            }}
            onContextMenu={(e) => e.preventDefault()}
            onMouseDown={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />
          {/* Banner message */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 50,
              left: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '8px',
              textAlign: 'center',
              zIndex: 2,
            }}
          >
            <Typography variant="caption">
              This is a preview. The downloaded biodata will have all the watermarking removed.
            </Typography>
          </Box>
        </Box>
        <Slider {...settings}>
          {templates.map((template) => (
            <Box 
              key={template.id}
              sx={{ 
                border: selectedTemplate?.id === template.id ? 2 : 0,
                borderColor: 'primary.main',
                cursor: 'pointer',
                p: 1,
                textAlign: 'center',
                width: '90% !important',
                margin: '0 auto',
                padding: '0px',
                pointerEvents: 'auto',
              }}
              onClick={() => handleTemplateSelect(template)}
            >
              <img src={template.iconUrl} alt={template.templateName} style={{ width: '100%', height: 'auto' }} />
              {renderPrice(template)}
            </Box>
          ))}
        </Slider>

        

        {/* Checkout/Download Button */}
        <Box sx={{ 
          position: 'fixed',
          bottom: 16,
          left: 16,
          right: 16,
          pointerEvents: 'auto' 
        }}>
          {(isPaymentLoading || isRedirecting) && (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.7)'
            }}>
              <CircularProgress size={24} />
            </Box>
          )}
          
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: '#ff3635', 
              borderRadius: 15, 
              mb: 2 
            }} 
            fullWidth
            disabled={!selectedTemplate || isPaymentLoading || isRedirecting}
            onClick={handleDownload}
          >
            {selectedTemplate?.pricing?.regions?.IN?.isFree ? 'Download' : 'Checkout and Download'}
          </Button>

          {/* Hidden Payment component that will be triggered by the button */}
          {selectedTemplate && !selectedTemplate.pricing?.regions?.IN?.isFree && (
            <Box sx={{ display: 'none' }}>
              <div id="payment-trigger-container">
                <Payment 
                  template={selectedTemplate}
                  formData={formData}
                  onSuccess={(response) => handlePaymentSuccess(response, selectedTemplate)}
                  onError={(error) => {
                    console.error('Payment failed', error);
                    setIsPaymentLoading(false);
                    // Handle payment failure
                  }}
                  onInit={handlePaymentInitiated}
                  onClose={handlePaymentClosed}
                />
              </div>
            </Box>
          )}
        </Box>
      </DialogContent>

      {/* Terms and Conditions Dialog */}
      <Dialog open={isTermsDialogOpen} onClose={handleTermsClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>
            Please check all the details carefully, as you won't be able to change/edit them after downloading the biodata.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            By proceeding, you agree to our 
            <Link to="/refund-policy" style={{ marginLeft: '4px', marginRight: '4px' }}>Refund Policy</Link>, 
            <Link to="/terms-of-service" style={{ marginLeft: '4px', marginRight: '4px' }}>Terms</Link>, and 
            <Link to="/privacy-policy" style={{ marginLeft: '4px' }}>Privacy Policy</Link>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTermsClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTermsAgree} sx={{ backgroundColor: '#ff3635', color: 'black' }}>
            Proceed & Download
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default TemplateSelectionMobile; 