import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import '../styles/TemplateSlider.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TemplateSlider = () => {
  return (
    <div className="template-slider-container">
      <Swiper
        modules={[Navigation, Autoplay]}
        navigation={true}
        spaceBetween={30}
        slidesPerView={3}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          // when window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is < 768px
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }}
        className="template-swiper"
      >
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg1.png" alt="Biodata Template 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg3.png" alt="Biodata Template 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg8.png" alt="Biodata Template 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg4.png" alt="Biodata Template 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg2.png" alt="Biodata Template 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg5.png" alt="Biodata Template 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg6.png" alt="Biodata Template 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/imgs/sampleBio/eg7.png" alt="Biodata Template 3" />
        </SwiperSlide>

      </Swiper>
    </div>
  );
};

export default TemplateSlider; 