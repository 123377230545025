import React from 'react';
import { Box, Container, Typography, Link as MuiLink, Grid, IconButton } from '@mui/material';
import { Email as EmailIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'black', py: 4 }}>
      <Container>
        <Grid container spacing={4}>
          {/* About Us Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom align="center" color="white">About Us</Typography>
            <Typography variant="body2" align="center" color="white">
            At Modern Shaadi, we specialize in helping you create elegant, personalized marriage biodatas that reflect your unique personality and values. Our beautifully designed templates and easy-to-use platform make the process seamless, ensuring you make a lasting impression. Start your journey to finding the perfect match with us today!
            </Typography>
          </Grid>

          {/* Connect with Us Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom align="center" color="white">Connect with Us</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton component={MuiLink} href="mailto:example@example.com">
                <EmailIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton component={MuiLink} href="https://instagram.com" target="_blank">
                <InstagramIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton component={MuiLink} href="https://facebook.com" target="_blank">
                <FacebookIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Grid>

          {/* Support Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom align="center" color="white">Support</Typography>
            <Box sx={{ textAlign: 'center' }}>
              <Link to="/terms-of-service" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" sx={{ color: 'white', '&:hover': { textDecoration: 'underline' } }}>
                  Terms of Service
                </Typography>
              </Link>
              <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" sx={{ color: 'white', '&:hover': { textDecoration: 'underline' } }}>
                  Privacy Policy
                </Typography>
              </Link>
              <Link to="/refund-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" sx={{ color: 'white', '&:hover': { textDecoration: 'underline' } }}>
                  Refund Policy
                </Typography>
              </Link>
              <Link to="/shipping-and-delivery-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" sx={{ color: 'white', '&:hover': { textDecoration: 'underline' } }}>
                  Shipping and Delivery Policy
                </Typography>
              </Link>
              <Link to="/contact-us" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" sx={{ color: 'white', '&:hover': { textDecoration: 'underline' } }}>
                  Contact Us
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" align="center" sx={{ mt: 4, color: 'white' }}>
          Designed with ❤️ by Shahnaj Laila
        </Typography>
        <Typography variant="body2" align="center" sx={{ color: 'white' }}>
          © {new Date().getFullYear()} Modern Shaadi. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer; 