import React from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  CardActionArea,
  Grid,
  Box 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { blogs } from '../data/blogs';
import Navbar from './Navbar';
import Footer from './Footer';

function BlogList() {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Blog
        </Typography>
        <Typography variant="h6" color="text.secondary" align="center" sx={{ mb: 6 }}>
          Tips, guides and best practices for creating the perfect wedding biodata
        </Typography>

        <Grid container spacing={4}>
          {blogs.map((blog) => (
            <Grid item xs={12} md={6} key={blog.slug}>
              <Card>
                <CardActionArea onClick={() => navigate(`/blog/${blog.slug}`)}>
                  {blog.coverImage && (
                    <Box
                      component="img"
                      src={blog.coverImage}
                      alt={blog.title}
                      sx={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {blog.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {blog.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
                      {new Date(blog.date).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default BlogList; 