import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Analytics from '../services/analyticsService';

/**
 * A higher-order component that tracks page views
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render
 */
function AnalyticsRoute({ children }) {
  const location = useLocation();
  
  useEffect(() => {
    // Track page view when the location changes
    const path = location.pathname;
    const title = getPageTitle(path);
    
    Analytics.trackPageView(path, title);
    
    // Track any query parameters
    if (location.search) {
      Analytics.trackEvent('Navigation', 'page_view_with_params', path, null, {
        search_params: location.search
      });
    }
    
    // Track page load time
    const loadTime = performance.timing ? 
      (performance.timing.loadEventEnd - performance.timing.navigationStart) / 1000 : 
      null;
    
    if (loadTime) {
      Analytics.trackEvent('Performance', 'page_load', path, loadTime);
    }
  }, [location]);
  
  // Helper function to get page title based on path
  const getPageTitle = (path) => {
    const pathMap = {
      '/': 'Home',
      '/biodata-download': 'Biodata Download',
      '/privacy-policy': 'Privacy Policy',
      '/terms-of-service': 'Terms of Service',
      '/refund-policy': 'Refund Policy',
      '/shipping-and-delivery': 'Shipping and Delivery',
      '/contact': 'Contact Us',
      '/about': 'About Us',
      '/blog': 'Blog',
      '/login': 'Login',
      '/signup': 'Sign Up',
    };
    
    // If it's a blog post
    if (path.startsWith('/blog/')) {
      return 'Blog Post';
    }
    
    return pathMap[path] || 'Unknown Page';
  };
  
  return children;
}

export default AnalyticsRoute; 