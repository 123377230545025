import React from 'react';
import { Typography, Container } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TermsOfService = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Typography variant="h4" gutterBottom align="center">Terms & Conditions</Typography>
        <Typography variant="body1">
        You should carefully read these terms before using the app because they will automatically apply to you if you use it. The program, any of its components, and our trademarks cannot be copied or altered in any manner. You are not permitted to try to extract the app's source code, convert it into another language, or create derivative copies of it. Modern Shaadi retains ownership of the app, as well as all associated trademarks, copyright, database rights, and other intellectual property.
        <br />
        <br />
        Modern Shaadi commits to making the software as effective and helpful as possible. We therefore reserve the right, at any time and for any reason, to alter the app or to impose fees for its services. We will always make sure you understand exactly what you are paying for before charging you for the app or its services.
        <br />
        <br />
        In order to deliver my service, the Modern Shaadi stores and processes the personal information you have given us. Maintaining the security of your phone and app access is your responsibility. As a result, we advise against jailbreaking or rooting your phone, which involves getting rid of software constraints and restrictions set by the device's original operating system. It may damage your phone's security features, leave it open to malware, viruses, and dangerous apps, and cause the Modern Shaadi to malfunction or not function at all.
        <br />
        <br />
        Third-party services that disclose their terms and conditions are used by the app.
        <br />
        <br />
        Link to Terms and Conditions of third-party service providers used by the app Google Analytics for Firebase
        <br />
        <br />
        You should be informed that Modern Shaadi will not be held accountable for certain things. The software needs an active internet connection to perform some of its features. Wi-Fi or your mobile network provider may be used for the connection, but Modern Shaadi cannot be held accountable for the app's inability to function properly if you are without Wi-Fi or have used up all of your data allotment.
        <br />
        <br />
        If you are using the app outside of a Wi-Fi-enabled region, keep in mind that the conditions of your mobile network provider's agreement will still be in effect. Your mobile operator may therefore charge you for the cost of data for the duration of the connection while using the app, in addition to other third-party fees. When you use the app outside of your home territory (i.e., region or nation) without turning off data roaming, you accept responsibility for any such charges, including roaming data charges. Please be advised that we presume you have permission from the bill payer to use the app if you are not the one who pays the bill for the device you are using it on.
        <br />
        <br />
        Similarly, Modern Shaadi does not necessarily assume liability for how you use the software, i.e. It is your duty to keep your device charged; Modern Shaadi cannot be held accountable if the battery runs out and you are unable to switch it on to use the service.
        <br />
        <br />
        Regarding Modern Shaadi’s liability for your use of the app, it's important to keep in mind that, despite our best efforts to keep it current and accurate at all times, we do depend on outside parties to supply us with information so that we can make it accessible to you. Modern Shaadi disclaims all responsibility for any loss—direct or indirect—that you may incur from depending only on this app feature.
        <br />
        <br />
        <strong>Changes to This Terms and Conditions</strong>
        <br />
        We might occasionally make changes to our terms and conditions. It is therefore recommended that you check this website from time to time for any updates. We will put the updated Terms and Conditions on this page to let you know about any changes.
        <br />
        <br />
        These terms and conditions are effective as of 2025-03-01
        <br />
        <br />
        <strong>Contact Us</strong>
        <br />
        If you have any questions or suggestions about my Terms and Conditions, do not hesitate to contact me at <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a> 
        <br />
        <br />
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfService; 