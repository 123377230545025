import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Container, 
  Typography, 
  Paper,
  CircularProgress,
  Alert
} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { drawTemplateOnCanvas } from './TemplateSelectionHelper';
import { jsPDF } from 'jspdf';
import { storage } from '../services/firebaseConfig';
import { ref, uploadString } from 'firebase/storage';
import * as Analytics from '../services/analyticsService';
import { getFunctions, httpsCallable } from 'firebase/functions';

function TemplateDownload() {
  const location = useLocation();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const conversionReportedRef = useRef(false);
  const [paymentVerified, setPaymentVerified] = useState(false);
  const functions = getFunctions();

  const { template, formData, paymentResponse, selectedFont } = location.state || {};

  // Calculate canvas size based on container width while maintaining A4 aspect ratio
  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        // A4 aspect ratio is 1:1.414 (width:height)
        const containerHeight = containerWidth * 1.414;
        
        setCanvasSize({
          width: containerWidth,
          height: containerHeight
        });
      }
    };

    // Initial size calculation
    updateCanvasSize();
    
    // Update on window resize
    window.addEventListener('resize', updateCanvasSize);
    
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  // Define reportConversion function outside of useEffect so it can be accessed by both hooks
  const reportConversion = () => {
    // Skip if no payment response
    if (!paymentResponse) return;
    
    // Create a unique key for this transaction
    const transactionId = paymentResponse?.razorpay_order_id || paymentResponse?.razorpay_payment_id;
    if (!transactionId) return;
    
    const storageKey = 'conversion_reported_' + transactionId;
    
    // Check if we've already reported this conversion
    if (conversionReportedRef.current || sessionStorage.getItem(storageKey)) {
      return;
    }
    
    try {
      // Get the amount from the payment response
      const amount = paymentResponse.amount || 0;
      
      // Track purchase using our analytics service
      Analytics.trackPurchase(
        transactionId,
        Math.round(amount / 100), // Convert from paise to rupees
        template?.id || 'unknown-template',
        template?.templateName || 'Biodata Template'
      );
      
      // Also track Google Ads specific conversion
      Analytics.trackGoogleAdsConversion(
        transactionId,
        Math.round(amount / 100), // Convert from paise to rupees
        template?.id || 'unknown-template',
        template?.templateName || 'Biodata Template'
      );
      
      // Also track a successful download page view
      Analytics.trackPageView('/biodata-download', 'Biodata Download Page');
      
      // Mark as reported in both ref and sessionStorage
      conversionReportedRef.current = true;
      sessionStorage.setItem(storageKey, 'true');
      console.log('Conversion reported successfully');
    } catch (error) {
      console.error('Error reporting conversion:', error);
      Analytics.trackError('conversion_tracking', error.message, 'TemplateDownload');
    }
  };

  useEffect(() => {
    const verifyPaymentStatus = async () => {
      // Skip if no payment response
      if (!paymentResponse) {
        setError('No payment information found');
        return;
      }
      
      try {
        // Call a new Cloud Function to verify the payment
        const checkPaymentStatus = httpsCallable(functions, 'checkPaymentStatus');
        const { data } = await checkPaymentStatus({
          orderId: paymentResponse.razorpay_order_id,
          paymentId: paymentResponse.razorpay_payment_id
        });
        
        if (data.verified) {
          setPaymentVerified(true);
          // Only track conversion if payment is verified
          reportConversion();
        } else {
          setError('Payment verification failed');
          // Track attempted fraud
          Analytics.trackError('payment_verification', 'Payment verification failed', 'TemplateDownload');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setError('Error verifying payment');
      }
    };
    
    verifyPaymentStatus();
  }, [paymentResponse]);

  const handleDownload = async () => {
    if (!paymentVerified) {
      setError('Payment verification required before download');
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      setError('Canvas not found');
      Analytics.trackError('download_error', 'Canvas not found', 'TemplateDownload');
      return;
    }

    try {
      setLoading(true);

      // Track download initiation
      Analytics.trackEvent('Conversion', 'download_initiated', template?.templateName, null, {
        template_id: template?.id
      });

      // Ensure canvas is fully rendered
      const isCanvasBlank = canvas.toDataURL() === document.createElement('canvas').toDataURL();
      if (isCanvasBlank) {
        throw new Error('Canvas is blank');
      }

      // For PDF generation, we'll use a higher resolution canvas
      const tempCanvas = document.createElement('canvas');
      // A4 at 300 DPI
      tempCanvas.width = 2480;
      tempCanvas.height = 3508;
      
      // Create a copy of formData with absolute URLs for images
      const enhancedFormData = { ...formData };
      
      // If profile photo exists and is a data URL, we need to ensure it's fully loaded
      if (formData.profilePhoto) {
        // Create a promise to load the image
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            // Image loaded successfully
            resolve();
          };
          img.onerror = () => {
            reject(new Error('Failed to load profile image'));
          };
          img.src = formData.profilePhoto;
        });
      }
      
      // Draw the template with form data at high resolution, including the selected font
      await drawTemplateOnCanvas(
        null, // No canvas ID needed
        template.templateUrl,
        template,
        enhancedFormData,
        tempCanvas, // Pass the canvas directly
        selectedFont // Pass the selected font
      );
      
      // Add a small delay to ensure everything is rendered
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Create PDF with A4 dimensions
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
        compress: true
      });

      // Get canvas data with maximum quality
      const imgData = tempCanvas.toDataURL('image/jpeg', 1.0);
      
      // Add the image to PDF with proper dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add image with high quality settings
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');

      // Save with a more descriptive filename
      const filename = `${paymentResponse?.razorpay_order_id || 'biodata-document'}.pdf`;
      
      // Save PDF to local
      pdf.save(filename);

      // Upload PDF to Firebase Storage
      const pdfData = pdf.output('datauristring'); // Get PDF as data URI
      const storageRef = ref(storage, `userBiodata/${filename}`); // Create a reference to the storage path
      await uploadString(storageRef, pdfData, 'data_url'); // Upload the PDF
      
      // Track successful download
      Analytics.trackDownload('pdf', filename, true);
      
    } catch (error) {
      console.error('PDF Generation Error:', error);
      setError(`Failed to generate PDF: ${error.message}`);
      Analytics.trackError('pdf_generation_error', error.message, 'TemplateDownload');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const renderTemplate = async () => {
      if (!template || !formData || !paymentResponse) {
        setError('Missing required data');
        setLoading(false);
        return;
      }

      const canvas = canvasRef.current;
      if (!canvas) {
        setError('Canvas element not found');
        return;
      }

      try {
        // Canvas dimensions will be set by the canvasSize state
        canvas.width = canvasSize.width * 2; // Multiply by 2 for better quality
        canvas.height = canvasSize.height * 2;
        
        if (canvas.width > 0 && canvas.height > 0) {
          // Draw the template with form data, including the selected font
          await drawTemplateOnCanvas(
            null,
            template.templateUrl,
            template,
            formData,
            canvas,
            selectedFont // Pass the selected font here
          );

          // Add a small delay to ensure canvas is fully rendered
          await new Promise(resolve => setTimeout(resolve, 500));
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Template Rendering Error:', err);
        setError(`Failed to render template: ${err.message}`);
        setLoading(false);
      }
    };

    if (canvasSize.width > 0) {
      renderTemplate();
    }
  }, [template, formData, paymentResponse, canvasSize, selectedFont]); // Add selectedFont to dependencies

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      // Disable right-click menu on canvas
      canvas.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });

      // Disable copy command
      canvas.addEventListener('copy', (e) => {
        e.preventDefault();
      });

      // Disable drag start
      canvas.addEventListener('dragstart', (e) => {
        e.preventDefault();
      });
    }
  }, []);

  // Track button clicks
  const handleCreateAnother = () => {
    navigate('/');
  };

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
        <Button 
          variant="contained" 
          onClick={() => navigate('/')}
          sx={{ mt: 2 }}
        >
          Return Home
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Your Biodata is Ready!
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3 }}>
          Thank you for your purchase. Your biodata has been generated and is ready for download.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, paddingTop: 2, paddingBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            disabled={loading}
          >
            Download PDF
          </Button>
          
          <Button
            variant="outlined"
            onClick={handleCreateAnother}
          >
            Create Another
          </Button>
        </Box>
        <Box 
          ref={containerRef}
          sx={{ 
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mb: 3,
            overflow: 'hidden'
          }}
        >
          {loading && (
            <Box sx={{ 
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2
            }}>
              <CircularProgress />
            </Box>
          )}
          
          <Box sx={{ 
            position: 'relative',
            width: canvasSize.width,
            height: canvasSize.height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <canvas
              ref={canvasRef}
              id="downloadCanvas"
              style={{
                width: '100%',
                height: '100%',
                display: loading ? 'none' : 'block',
                border: '1px solid #ccc',
                WebkitUserSelect: 'none',
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                pointerEvents: 'none',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent',
                zIndex: 1,
                cursor: 'not-allowed',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'transparent',
                  pointerEvents: 'auto',
                }
              }}
              onContextMenu={(e) => e.preventDefault()}
              onMouseDown={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default TemplateDownload; 