import React from 'react';
import { Typography, Container } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Typography variant="h4" gutterBottom align="center">Privacy Policy</Typography>
        <Typography variant="body1">
        Modern Shaadi built the Modern Shaadi as a Commercial app. This SERVICE is provided by Modern Shaadi and is intended for use as is.
        <br />
        <br />
        This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
        <br />
        <br />
        By using our service, you consent to the gathering and use of data in accordance with this policy. The purpose of the personal data we gather is to deliver and enhance the service. Except as specified in this privacy policy, we will not use or disclose your information to third parties.
        <br />
        <br />
        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Modern Shaadi unless otherwise defined in this Privacy Policy.
        <br />
        <br />
        <strong>Information Collection and Use</strong>
        <br />
        We may ask for certain personally identifiable information from you to improve your experience using our service. The data we ask for will be stored on your device; we do not gather it in any manner.
        <br />
        <br />
        The app does use third-party services that may collect information used to identify you.
        <br />
        <br />
        Link to the privacy policy of third-party service providers used by the app Google Analytics for Firebase Log Data
        <br />
        <br />
        <strong>Cookies</strong>
        <br />
        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
        <br />
        <br />
        These "cookies" are not specifically used by this service. However, the application might make use of third-party code and libraries that employ "cookies" to gather data and enhance its offerings. You are aware when a cookie is being delivered to your device and may choose to accept or reject them. You might not be able to utilise some parts of this service if you decide to reject our cookies.
        <br />
        <br />
        <strong>Service Providers</strong>
        <br />
        In order to support our service, deliver the service on our behalf, carry out service-related tasks, or help us analyse how our service is used, we may hire third-party businesses and individuals.
        <br />
        <br />
        We would want to let users know that their personal information is accessible to these third parties. The purpose is to carry out the duties that have been delegated to them on our behalf. They must, however, refrain from disclosing or using the information for any other reason.
        <br />
        <br />
        <strong>Security</strong>
        <br />
        We work to employ commercially reasonable measures of preserving your personal information because we respect your faith in us. However, keep in mind that no form of electronic storage or transmission over the internet is 100% safe and dependable, and we are unable to guarantee complete security.
        <br />
        <br />
        <strong>Links to Other Sites</strong>
        <br />
        There may be links to other websites within this service. A third-party link will take you to that website if you click on it. Please be aware that we do not run these external websites. As a result, we highly recommend that you read these websites' privacy policies. The content, privacy practices, and policies of any third-party websites or services are beyond our control and responsibility.
        <br />
        <br />
        <strong>Children's Privacy</strong>
        <br />
        These Services do not address anyone under the age of 13. We do not intentionally gather personally identifying information from kids younger than thirteen. We promptly remove any personal information from our servers if we find that it was submitted by a child under the age of thirteen. Please get in touch with us if you are a parent or guardian and you know that your child has given us personal information so that we can take the appropriate action.
        <br />
        <br />
        <strong>Changes to This Privacy Policy</strong>
        <br />
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
        <br />
        <br />
        This policy is effective as of 2025-03-01
        <br />
        <br />
        <strong>Contact Us</strong>
        <br />
        If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a>
        <br />
        <br />
        This site is owned by Rahul Islam and Shahnaj Laila.
        <br />
        <br />
        Operational Address: Baihata Chariali, Guwahati, Assam, 781381
        <br />
        <br />
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy; 