import React from 'react';
import { Typography, Container } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Typography variant="h4" align="center" gutterBottom>Contact Us</Typography>
        <Typography variant="body1">
        We would love to hear from you! For any questions or support, feel free to reach out to us at: <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a>
        <hr />
        Support Hours:
        <br />
        Monday - Friday: 9 AM to 6 PM IST
        <br />
        Email: <a href="mailto:support@modernshaadi.com">support@modernshaadi.com</a>   
        <br />
        <br />
        <img src="/imgs/support.jpg" alt="Support Team" style={{ width: '100%', height: 'auto' }} />

        <br />
        <br />
        
      
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs; 