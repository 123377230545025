import ReactGA from 'react-ga4';

/**
 * General event tracking function
 * @param {string} category - Event category
 * @param {string} action - Event action
 * @param {string} label - Event label (optional)
 * @param {number} value - Event value (optional)
 * @param {Object} additionalParams - Additional event parameters (optional)
 */
export const trackEvent = (category, action, label = null, value = null, additionalParams = {}) => {
  try {
    const eventParams = {
      category,
      action,
      ...additionalParams
    };
    
    if (label) eventParams.label = label;
    if (value !== null && !isNaN(value)) eventParams.value = value;
    
    ReactGA.event(eventParams);
  } catch (error) {
    console.error('Error tracking GA event:', error);
  }
};

/**
 * Track page views
 * @param {string} path - Page path
 * @param {string} title - Page title (optional)
 */
export const trackPageView = (path, title = null) => {
  try {
    ReactGA.send({ hitType: "pageview", page: path, title });
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
};

// Form Interactions
export const trackFormField = (fieldName, action = 'focus', formName = 'biodata-form') => {
  trackEvent('Form Interaction', action, `${formName}: ${fieldName}`);
};

export const trackFormProgress = (stepName, formName = 'biodata-form') => {
  trackEvent('Form Progress', 'completed_step', `${formName}: ${stepName}`);
};

export const trackFormSubmission = (formName = 'biodata-form', success = true) => {
  trackEvent('Form Submission', success ? 'success' : 'error', formName);
};

export const trackFormPartialCompletion = (completionPercentage, formName = 'biodata-form') => {
  trackEvent('Form Progress', 'partial_completion', formName, Math.round(completionPercentage));
};

// Template Selection Interactions
export const trackTemplateView = (templateId, templateName) => {
  trackEvent('Template Interaction', 'view', templateName, null, { template_id: templateId });
};

export const trackTemplateSelection = (templateId, templateName, price) => {
  trackEvent('Template Interaction', 'select', templateName, price, { 
    template_id: templateId,
    is_free: price === 0 || price === 'FREE'
  });
};

export const trackFontSelection = (fontName, templateId = null) => {
  trackEvent('Template Customization', 'font_change', fontName, null, { 
    template_id: templateId
  });
};

// Payment and Checkout Journey
export const trackCheckoutStart = (templateId, templateName, price) => {
  trackEvent('Checkout', 'initiate', templateName, price, {
    template_id: templateId,
    currency: 'INR'
  });
};

export const trackCheckoutProgress = (stage, success = true, errorMessage = null) => {
  trackEvent('Checkout', success ? 'step_complete' : 'step_error', stage, null, {
    error_message: errorMessage
  });
};

export const trackPaymentAttempt = (paymentMethod, amount) => {
  trackEvent('Payment', 'attempt', paymentMethod, amount);
};

export const trackPurchase = (transactionId, revenue, templateId, templateName) => {
  // Use ReactGA purchase event with e-commerce parameters
  ReactGA.event({
    category: 'Conversion',
    action: 'purchase',
    label: templateName,
    value: revenue,
    transaction_id: transactionId,
    items: [{
      item_id: templateId,
      item_name: templateName,
      price: revenue,
      quantity: 1
    }]
  });
};

/**
 * Specifically track Google Ads conversion for purchases
 * This function maintains the exact format needed for Google Ads conversion tracking
 */
export const trackGoogleAdsConversion = (transactionId, revenue, templateId, templateName) => {
  ReactGA.event({
    category: 'Conversion',
    action: 'ads_conversion_PURCHASE_1',
    label: 'Template Purchase',
    value: revenue,
    property_name: 'modern-shaadi',
    // Include additional e-commerce data
    items: [{
      item_id: templateId,
      item_name: templateName,
      price: revenue,
      quantity: 1
    }],
    // Include transaction data
    transaction_id: transactionId,
  });
};

// User Journey and Navigation
export const trackNavigation = (linkName, destination) => {
  trackEvent('Navigation', 'click', linkName, null, { destination });
};

export const trackButtonClick = (buttonName, section = 'general') => {
  trackEvent('Engagement', 'button_click', `${section}: ${buttonName}`);
};

export const trackModalOpen = (modalName) => {
  trackEvent('UI Interaction', 'modal_open', modalName);
};

export const trackModalClose = (modalName, action = 'close') => {
  trackEvent('UI Interaction', 'modal_close', `${modalName}: ${action}`);
};

export const trackDownload = (fileType, fileName, success = true) => {
  trackEvent('Conversion', 'download', fileType, null, {
    file_name: fileName,
    success
  });
};

// Session and User Info
export const trackSessionStart = (referrer = null) => {
  trackEvent('Session', 'start', null, null, { referrer });
};

export const trackUserAuthentication = (method, success = true) => {
  trackEvent('User', 'authentication', method, null, { success });
};

export const trackExitIntent = (currentPage, timeOnPage) => {
  trackEvent('Engagement', 'exit_intent', currentPage, Math.round(timeOnPage));
};

// Error Tracking
export const trackError = (errorType, errorMessage, componentName = null) => {
  trackEvent('Error', errorType, errorMessage, null, { component: componentName });
}; 